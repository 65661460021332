import { useDispatch } from 'react-redux';
import NFTServices from '../api/NFTServices';
import { useCookies } from "react-cookie";
import { getnft,allNft }  from '../store/nft/action'
import { identity } from 'lodash';

export default function useNFT() {
    const dispatch = useDispatch();

    return {
        create: async (data) => {          
            var responseData = await NFTServices.Create(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },

        sellListing: async (data, network, contract_address, token_id) => {          
            var responseData = await NFTServices.SellListing(data, network, contract_address, token_id);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },

        buyListing: async (data, urlNetwork, contractAddress, tokenId) => {          
            var responseData = await NFTServices.BuyListing(data, urlNetwork, contractAddress, tokenId);
            if (responseData.status === 200) {
                return responseData;
            }
            return false;
        },
        update: async (data, network, contract_address, token_id) => {               
            var responseData = await NFTServices.Update(data, network, contract_address, token_id);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        mintNft: async (network, contract_address, token_id) => {
            var responseData = await NFTServices.MintNft(network, contract_address, token_id);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        deleteNFT: async (params) => {
            var responseData = await NFTServices.DeleteNon_mintedNft(params);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },

        collectionsOfUser: async (data) => {          
            var responseData = await NFTServices.CollectionOfUser();
            if (responseData.status === 200) {
                return responseData.data.data.collections;
            }
            return false;
        },
        nftWithPagination: async (data) => {          
            var responseData = await NFTServices.GetNFTWithPagination(data);
            if (responseData.status === 200) {
                dispatch(allNft(responseData.data.data))
                return responseData.data.data;
            }
            return false;
        },
        getNft: async (data) => {
            console.log('dispatch getNft', data);
            dispatch(getnft(data))
        },
        getNftDetails: async (data) => {
            var responseData = await NFTServices.GetNftDetails(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        /*nftByCollection: async (data) => {          
            var responseData = await NFTServices.GetManyNFTWithPaginationByCollection(data);
            if (responseData.status === 200) {
                dispatch(allNft(responseData.data.data.nft_items))
                return responseData.data.data.nft_items;
            }
            return false;
        },*/
        saveReport: async (data) => {          
            var responseData = await NFTServices.SaveReport(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        /*filterNFT: async (data) => {          
            var responseData = await NFTServices.FilterNftByName(data);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },*/
        transferNFT: async (data, urlNetwork, contractAddress, tokenId) => {               
            var responseData = await NFTServices.TransferNFT(data, urlNetwork, contractAddress, tokenId);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        burnSelectedNft: async (data, chianName, contractAddress, tokenId) => {
            var responseData = await NFTServices.BurnSelectedNft(data, chianName, contractAddress, tokenId);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        likeUnlikeNft: async (data) => {    
            let responseData = await NFTServices.LikeUnLikeNFT(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        fetchFavNft: async (data) => {    
            let responseData = await NFTServices.FetchFavNft(data);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        cancelPriceList: async (id) => {               
            var responseData = await NFTServices.CancelSellListing(id);
            if (responseData.status === 200) {
                return responseData.data;
            }
            return false;
        },
        ownerList: async (urlNetwork, contract_address, token, wallet_address = null) => {         
            var responseData = await NFTServices.OwnerList(urlNetwork, contract_address, token, wallet_address);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        getPriceList: async (urlNetwork, contract_address, token, wallet_address = null) => {         
            var responseData = await NFTServices.PriceList(urlNetwork, contract_address, token, wallet_address);
            if (responseData.status === 200) {
                return responseData.data.data.price_list;
            }
            return false;
        },
        fetchNftUnlockableContent: async ( urlNetwork, contract_address, token) => {
            var responseData = await NFTServices.FetchNftUnlockableContent(urlNetwork, contract_address, token);
            if (responseData.status === 200) {
                return responseData.data.data;
            }
            return false;
        },
        
         
    }
}
