import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/thumbs/thumbs.min.css";

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper";
import { useHistory } from "react-router";
import { NavLink } from 'react-router-dom';
import { URLS } from "../../utils/app.constant";
import { isMobile } from "../../utils/helper";

const SwiperSlider = forwardRef((props, ref) => {
  const history = useHistory();
  const { tagChange, sliderData } = props;

  const [swiperRef, setSwiperRef] = useState(null);
  const [autoplayObj, setAutoplayObj] = useState({ delay: 5000, disableOnInteraction: false });
  const [doChangeSetting, setDoChangeSetting] = useState(true);
  const [initialSlide, setInitialSlide] = useState(0);
  const [addHover, setAddHover] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    if (isMobile()) {
      if (sliderData && sliderData.length > 0) {
        sliderData.map((file, id) => {
          // if (id === parentId) {
            if(file.nfts && file?.nfts.length>0) {
              file?.nfts.map((data, key) => {
                // if (key === childId) {    
                data.isHover = false;
                // } else {
                //   data.isHover = false;
                // }
              })
            }
          // }
        })
      }
      setAddHover(true);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    slideTo(activeIndex) {
      swiperRef && swiperRef.slideTo && swiperRef.slideTo(activeIndex);
    },
  }));

  const onPageChangeHandler = (page) => {
    if (doChangeSetting && page === 0) {
      // setDoChangeSetting(false);
      // setAutoplayObj({ delay: 5000});
    }

    tagChange && tagChange(page);
  };

  const gotoDetailsPageHandler = (selectedData) => {
    if (selectedData?.username) {
      history.push(`/profile/${selectedData.username}`);
    } else if (selectedData?.item_name && selectedData?.token_id) {
      history.push(
        `${URLS.NFT_DETAILS}/${selectedData.network === 'polygon'? 'matic':selectedData.network}/${selectedData.contract_address}/${selectedData.token_id}`
      );
    }
  };

  const hoverClassAdd = (item, childId, parentId) => {
    // console.log('item, id', item, childId, parentId);
    // console.log('slider', sliderData, sliderData[parentId].nfts[childId]);
    if (isMobile()) {
      if (sliderData && sliderData.length > 0) {
        sliderData.map((file, id) => {
          // if (id === parentId) {
          if(file.nfts && file?.nfts.length>0) {
            file.nfts.map((data, key) => {
              if (key === childId && id === parentId) {
                data.isHover = true;
                forceUpdate();
              } else {
                data.isHover = false;
              }
            })
          }
          // }
        })
      }
      setAddHover(true);
    } else {
      setAddHover(false)
    }
  }

  return (
    <>
      <Swiper
        // initialSlide={initialSlide}
        // effect={"coverflow"}
        grabCursor={true}
        // centeredSlides={true}
        // slidesPerView={"auto"}
        // coverflowEffect={{
        //   rotate: 50,
        //   stretch: 0,
        //   depth: 100,
        //   modifier: 1,
        //   slideShadows: true,
        // }}
        Navigation
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        spaceBetween={50}
        onSwiper={(swiper) => {
          setSwiperRef(swiper);
        }}
        onPaginationUpdate={(e) => onPageChangeHandler(e.activeIndex)}
        className="mySwiper"
        navigation={true}
        loop={true}
        // autoplay={{ ...autoplayObj }}
        // autoplay={false}
      >
        {sliderData && sliderData.length
          ? sliderData.map((file, i) => {
            return (
              <SwiperSlide key={i}>
                {
                  i === 0 ?
                  <div className="container banner-first-container">
                    <div className="banner-first">
                      <div className="row">
                        <div className="col-4">
                        <div className="first-banner-box">
                            <div className="banner-box-img">
                              <div className="discount-tag">0% OFF</div>
                              <img src="img/m-box.png" alt="Mystery Box" />
                            </div>
                            <h2>EverGrowMan Box of 1 NFT</h2>
                            <div className="des-banner">
                              <h5>1 NFT</h5>
                              Own a piece of DeFi history &amp; the chance to win a Tesla! A collection of 15,000 unique and beautiful NFTs capturing EverGrowMan, the superhero of the EverGrow ecosystem. Each mystery box contains randomly allocated EverGrowMan NFTs, including the chance to own an ultra-rare SpaceX &amp; Tesla edition. Every NFT contained within these mystery boxes gives you one entry into a draw to win a Tesla Model 3 or 50,000 BUSD. All holders of EverGrowMan NFTs will receive 2 future airdrops of EverGrow's ecosystem token, Lucro.
                              <div className="btn-go-wrap"><NavLink to="/assets/binance/0x62027611E88715F3D838E6930d5303cBc05D6DD6/1">Go</NavLink></div>
                              </div>
                            <div className="price-banner-box"><em>Price</em><span>100 BUSD</span></div>
                          </div>
                        </div>
                        <div className="col-4">
                        <div className="first-banner-box">
                            <div className="banner-box-img"> 
                              <div className="discount-tag">10% OFF</div>
                              <img src="img/m-box.png" alt="Mystery Box" />
                            </div>
                            <h2>EverGrowMan Box of 3 NFTs</h2>
                            <div className="des-banner">
                            <h5>3 NFTs</h5>
                              Own a piece of DeFi history &amp; the chance to win a Tesla! A collection of 15,000 unique and beautiful NFTs capturing EverGrowMan, the superhero of the EverGrow ecosystem. Each mystery box contains randomly allocated EverGrowMan NFTs, including the chance to own an ultra-rare SpaceX &amp; Tesla edition. Every NFT contained within these mystery boxes gives you one entry into a draw to win a Tesla Model 3 or 50,000 BUSD. All holders of EverGrowMan NFTs will receive 2 future airdrops of EverGrow's ecosystem token, Lucro.
                              <div className="btn-go-wrap"><NavLink to="/assets/binance/0x62027611E88715F3D838E6930d5303cBc05D6DD6/2">Go</NavLink></div>
                              </div>
                            <div className="price-banner-box"><em>Price</em><span>270 BUSD</span></div>
                          </div>
                        </div>
                        <div className="col-4">
                        <div className="first-banner-box">
                            <div className="banner-box-img">
                              <div className="discount-tag">15% OFF</div>
                              <img src="img/m-box.png" alt="Mystery Box" />
                            </div>
                            <h2>EverGrowMan Box of 5 NFTs</h2>
                            <div className="des-banner">
                            <h5>5 NFTs</h5>
                              Own a piece of DeFi history &amp; the chance to win a Tesla! A collection of 15,000 unique and beautiful NFTs capturing EverGrowMan, the superhero of the EverGrow ecosystem. Each mystery box contains randomly allocated EverGrowMan NFTs, including the chance to own an ultra-rare SpaceX &amp; Tesla edition. Every NFT contained within these mystery boxes gives you one entry into a draw to win a Tesla Model 3 or 50,000 BUSD. All holders of EverGrowMan NFTs will receive 2 future airdrops of EverGrow's ecosystem token, Lucro.
                              <div className="btn-go-wrap"><NavLink to="/assets/binance/0x62027611E88715F3D838E6930d5303cBc05D6DD6/3">Go</NavLink></div>
                              </div>
                            <div className="price-banner-box"><em>Price</em><span>425 BUSD</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> :
                  <>
                    <div className="container-left">
                    {file?.nfts?.length && file?.nfts.map((newfile, key1) => {
                      // {console.log('newfile', newfile)}
                      return (
                        (key1 >= 0 && key1 <= 1) ? (
                          <div className={'image image-' + key1}>
                            <img
                              className="swiper-images"
                              key={key1}
                              src={
                                newfile.item_resized_image? newfile.item_resized_image : (newfile.item_image || newfile.profile_image)
                              }
                            />
                            <div className='hover-box' onClick={(e) => {
                              e.preventDefault(); gotoDetailsPageHandler(newfile);
                            }}>
                              <div className="top-part">
                                <h2>{newfile && newfile?.item_name && newfile?.item_name.slice(0, 18) || newfile?.username}</h2>
                                <h3>Exclusive Collection</h3>
                              </div>
                              <div className="bottom-part">
                                <em className="btn-look">GO</em>
                                <strong>{newfile?.network_chain_label}</strong>
                                {/* <span>Item</span> */}
                              </div>
                            </div>
                            {/* <p> {newfile?.item_name || newfile?.username}</p> */}
                          </div>
                        ) : "");
                    }) || ""}
                    </div>
                    <div className="container">
                      <div className="row">
                        {file?.nfts?.length &&
                          file?.nfts.map((newfile, key) => {

                            return (
                              (key >= 2 && key <= 7) ? (
                                <div
                                  className="col-4 aos-animate small-slide"
                                  data-aos="zoom-in-up"
                                  data-aos-delay="300"
                                  key={key}
                                >
                                  <a
                                    href="javascript:void(0);"
                                    className={newfile.isHover ? 'mobile-hover-box' : ''}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      hoverClassAdd(newfile, key, i);
                                    }}
                                  >
                                    {" "}
                                    {(!newfile.isHover || !isMobile()) && (
                                      <img
                                        className="swiper-images"
                                        key={key}
                                        src={
                                          newfile.item_resized_image? newfile.item_resized_image : (newfile.item_image || newfile.profile_image)
                                        }
                                      />
                                    )}
                                    {" "}


                                    <div className="hover-box" onClick={() => {
                                      gotoDetailsPageHandler(newfile);
                                    }}>
                                      {(newfile.isHover || !isMobile()) && (
                                        <>

                                          <div className="top-part">
                                            <h2>{newfile && newfile?.item_name && newfile?.item_name.slice(0, 18) || newfile?.username}</h2>
                                            <h3>Exclusive Collection</h3>
                                          </div><div className="bottom-part">
                                            <em className="btn-look">GO</em>
                                            <strong>{newfile?.network_chain_label}</strong>
                                          </div>
                                        </>
                                      )}
                                      {/* : (
                                          <img
                                          className="swiper-images"
                                          key={key}
                                          src={
                                            newfile.item_image || newfile.profile_image
                                          }
                                        />
                                      )} */}
                                    </div>
                                  </a>

                                  {/* <p> {newfile?.item_name || newfile?.username}</p> */}

                                </div>
                              ) : "");
                          }) || ""}
                      </div>
                    </div>
                    {/* NEW SLIDER */}
                    {/* {file?.collection_name ?
                      <div className="banner-wrap">
                        <div className="container">
                          <div className="banner-row">
                          <div className="banner-left">
                            <h2 className="slide-up">{file.collection_name}</h2>
                            <p>{file.description}</p>
                            <div className="btn-wrap">
                            <Link to={{
                                    pathname: `collection/${file.slug}`
                              }} >
                                View
                            </Link>
                            </div>
                        </div>
                        <div className="banner-right">
                            <div className="video-box">
                              <div className="boy-img"><img src={file.logo_image} /></div>
                              <div className="photobanner"><img className="banner" src={file.banner_image} /></div>
                            </div>
                        </div>
                          </div>
                        </div>
                      </div>:""
                    } */}
                    {/* NEW SLIDER */}
                    <div className="container-right">
                      {file?.nfts?.length && file?.nfts.map((newfile, key1) => {
                        return (
                          (key1 >= 8 && key1 <= 9) ? (
                            <div className={'image image-' + key1}>
                              <img
                                className="swiper-images"
                                key={key1}
                                src={
                                  newfile.item_resized_image? newfile.item_resized_image : (newfile.item_image || newfile.profile_image)
                                }
                              />
                              <div className='hover-box' onClick={(e) => {
                                e.preventDefault();
                                gotoDetailsPageHandler(newfile);
                              }}>
                                <div className="top-part">
                                  <h2>{newfile && newfile?.item_name && newfile?.item_name.slice(0, 18) || newfile?.username}</h2>
                                  <h3>Exclusive Collection</h3>
                                </div>
                                <div className="bottom-part">
                                  <em className="btn-look">GO</em>
                                  <strong>{newfile?.network_chain_label}</strong>
                                  {/* <span>Item</span> */}
                                </div>
                              </div>
                              {/* <p> {newfile?.item_name || newfile?.username}</p> */}
                            </div>
                          ) : "");
                      }) || ""}
                    </div>

                      {/* MYSTERY SLIDER */}
                      {/* {file?.collection_name ?
                      <div className="banner-wrap">
                        <div className="container">
                          <div className="banner-row">
                          <div className="banner-left">
                            <h2 className="slide-up">{file.collection_name}</h2>
                            <p>{file.description}</p>
                            <div className="btn-wrap">
                            <Link to={{
                                    pathname: `collection/${file.slug}`
                              }} >
                                View
                            </Link>
                            </div>
                        </div>
                        <div className="banner-right">
                            <div className="video-box">
                              <div className="boy-img"><img src={file.logo_image} /></div>
                              <div className="photobanner"><img className="banner" src={file.banner_image} /></div>
                            </div>
                        </div>
                          </div>
                        </div>
                      </div>:""
                    } */}

                  </>
                }
                
            </SwiperSlide>
            );
          })
          : null}
      </Swiper>
    </>
  );
});

export default SwiperSlider;
