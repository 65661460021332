import React from "react";
import { connect } from "react-redux";
import { notification, Spin } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import TextInput from "../FormElements/TextInput";
import { useNftApi } from "../../hooks/api/nftApiHook";
import { CloseOutlined, SmileOutlined } from "@ant-design/icons";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { switchNetwork } from "../../utils/helper";
import { useMintContractAbiSelector } from "../../hooks/useContractAbi";
import { useAppLoader } from "../../hooks/actions/appLoaderHook";
import { METADATA_BASE_URL, CONTRACT_ADDRESS_SPECIAL } from '../../utils/app.constant'

const mysteryboxRevealFormSchema = (maxCount) => {
  maxCount = maxCount ? Number(maxCount) : 1;

  return yup.object().shape({
    quantity: yup
      .number()
      .typeError("Must be a number")
      .max(maxCount, `Cannot be greater than ${maxCount}`)
      .required("Field is required"),
  });
};

const MysteryboxRevealForm = (props) => {
  const { revealMysteryBox, getMysteryBoxToken } = useNftApi();
  const { showLoader, hideLoader } = useAppLoader();
  const { library } = useWeb3React();
  const getMintAbi = useMintContractAbiSelector();
  const {
    summary,
    appLoaderStatus,
    successHandler,
    contractAddress,
    tokenId,
    selectedNetwork,
    walletAddresss,
    itemChainId,
    itemTokenStandard,
    itemNetwork,
    nft
  } = props;

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mysteryboxRevealFormSchema(summary.maxCanBeRevealed)),
  });

  const fetchMysterToken = ({ contractAddress, tokenId, quantity }) => {
    return new Promise((resolve, reject) => {
      getMysteryBoxToken(
        { urlParams: {selectedNetwork, contractAddress, tokenId, quantity } },
        (message, resp) => {
          if (!resp?.gifts?.boxes?.length || !resp?.gifts?.uuids?.length) {
            reject({ success: false, message: "Invalid Request" });
          }
          resolve({
            success: true,
            message,
            boxes: resp?.gifts?.boxes || [],
            uuids: resp?.gifts?.uuids || [],
          });
        },
        (message, error) => {
          reject({ success: false, message, error });
        }
      );
    });
  };

  const mintTokenHandler = ({
    tokenIdArray,
    jsonDataArray,
    quantityArray,
    walletAddresss,
    collectionKey,
    quantity
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const web3 = new Web3(library.provider);
        let currentChainId = await web3.eth.getChainId();

        if (currentChainId !== itemChainId) {
          switchNetwork(String(itemChainId), library).then(() =>
            resolve(
              mintTokenHandler({
                tokenIdArray,
                jsonDataArray,
                quantityArray,
                walletAddresss,
                collectionKey,
                quantity
              })
            )
          );
          return;
        }

        const { abi, mintAddress } = getMintAbi(
          itemTokenStandard,
          `${itemNetwork}_${itemChainId}`,
          props.nft.contract_address.toLowerCase()
        );

        let depContract = new web3.eth.Contract(abi, mintAddress);
        //mint(address to, uint256[] calldata tokenIds, string[] calldata tokenURIs, uint256[] calldata qty)
        // function reveal(uint256 tokenId, uint256[] calldata revealItems, string[] calldata tokenURIs, uint256[] calldata qty, uint256 _collectionId)
        // reveal(uint256 id, uint256[] calldata revealItems, string[] calldata tokenURIs, uint256 qty)
        
        var createMinfuntion = null;
        if ( nft.contract_address.toLowerCase() == CONTRACT_ADDRESS_SPECIAL.toLowerCase() ) {
          createMinfuntion = depContract.methods.reveal(
            tokenId,
            tokenIdArray,
            jsonDataArray,
            quantity
          );
        } else {
          createMinfuntion = depContract.methods.reveal(
            tokenId,
            tokenIdArray,
            jsonDataArray,
            quantityArray,
            collectionKey
          );
        }

        const performTxn = await createMinfuntion.send({
          from: walletAddresss,
        });
        if (!performTxn) {
          reject({ success: false, message: "Unable to mint" });
        }

        resolve({ success: true, transactionHash: performTxn.transactionHash });
      } catch (err) {
        reject({ success: false, message: err?.message, error: err });
      }
    });
  };

  const onSubmit = async (values) => {
    try {
      let quantity = values.quantity;
      const fetchMysterTokenResp = await fetchMysterToken({
        contractAddress,
        tokenId,
        quantity,
      });
      let uuids = fetchMysterTokenResp.uuids;
      let tokenIdArray = [];
      let jsonDataArray = [];
      let quantityArray = [];
      let collectionKey = null;

      fetchMysterTokenResp.boxes.map((boxVal) => {
        tokenIdArray.push(boxVal.token_id);
        // jsonDataArray.push(JSON.stringify(boxVal.nft_metadata));
        jsonDataArray.push( METADATA_BASE_URL+'/'+boxVal.contract_address+'/'+boxVal.token_id);
        quantityArray.push(Number(boxVal.quantity));
        collectionKey = boxVal.collection_key
      });
      showLoader();
      const mintTokenHandlerResp = await mintTokenHandler({
        tokenIdArray,
        jsonDataArray,
        quantityArray,
        walletAddresss,
        collectionKey,
        quantity
      });

      revealMysteryBox(
        { ...values, uuids, urlParams: { selectedNetwork, contractAddress, tokenId } },
        (message, resp) => {
          successHandler && successHandler();
          notification.open({
            message: "Success",
            description: message,
            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
          });
        },
        (message, error) => {
          notification.open({
            message: "Error",
            description: message,
            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
          });
        }
      );
      hideLoader();
    } catch (err) {
      hideLoader();
      notification.open({
        message: " ",
        description: err?.message,
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
    }
  };

  return (
    <>
    {/* {console.log('selectedNetwork', selectedNetwork)} */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="form-group">
              <label className="mb-2">Quantity*</label>
              <Controller
                control={control}
                name="quantity"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <TextInput
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    inputRef={ref}
                    type="text"
                    placeholder="Enter Quantity"
                  />
                )}
              />
            </div>
            <div className="text-danger">{errors?.quantity?.message}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <button
              disabled={appLoaderStatus}
              className="btn-reveal"
              type="submit"
            >
              {appLoaderStatus ? "Please wait..." : "Reveal"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    appLoaderStatus: state.comman.appLoaderStatus,
  };
};

export default connect(mapStateToProps)(MysteryboxRevealForm);
