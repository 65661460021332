import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import CalenderIcon from "../../image/CalenderIcon.png";
import WatchIcon from "../../image/WatchIcon.png";
import previewIcon from "../../image/previewIcon.png";
import { useHistory } from "react-router-dom";
import WalletConnectProvider from "@walletconnect/web3-provider";
import useNetworks from "../../hooks/useNetwork";
import { SERVICE_FEE, NetworkURLs, METADATA_BASE_URL, CONTRACT_ADDRESS_SPECIAL } from "../../utils/app.constant.js";
import Select from "react-select";
import { chainImageHandler } from "../../utils/helper";
import ViewPrice from '../../components/ItemPrice/viewPrice'



// custom hooks
import useNFT from "../../hooks/useNFT";
import useComman from "../../hooks/useComman";
import { useWeb3React } from "@web3-react/core";
import abi_marketplace_1155 from "../../abi/ABI_MarketPlace_1155.json";
import abi_marketplace_721 from "../../abi/ABI_Marketplace_721.json";
import abi_mint_1155 from "../../abi/ABI_Mint_1155.json";
import abi_mint_1155_special from "../../abi/ABI_Mint_1155_SPECIAL.json";
import abi_mint_721 from "../../abi/ABI_Mint_721.json";
import binancePriceImage from "../../image/networkPrice/binance.jpg";
import maticPriceImage from "../../image/networkPrice/matic.jpg";
import ethereumPriceImage from "../../image/networkPrice/ethereum.jpg";

import moment from "moment";
import { notification } from "antd";
import { TimePicker, Modal } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker } from "react-rainbow-components";
import abi from "../../abi/nft.json";
import marketPlaceabi from "../../abi/marketPlace.json";
import ERC721 from "../../abi/erc721global.json";

import {
  nftContractAddress,
  //   marketPlaceAddress,
  smartChainUrl,
} from "../../abi/address";

import {
  TokenStandard,
  MetadataDisplayType,
  MethodName,
} from "../../utils/app.constant";
import * as address from "../../abi/address.json";

import Web3 from "web3";
import { switchNetwork, stringCapitalize } from "../../utils/helper";
const web3 = new Web3(Web3.givenProvider);

const provider = new WalletConnectProvider({
  rpc: {
    56: "https://bsc-dataseed1.binance.org",
    // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  chainId: 56,
  network: "binance",
  qrcode: false,
});

const { confirm } = Modal;

function ListForSalePageComponent({ props, islogin, auth, nftDetails }) {
  const ref = useRef(false);
  const startPriceRef = useRef();
  const { network, contract_address, token_id } = useParams();
  const [type, setType] = useState("FIXED");
  const [duration, setDuration] = useState("1_day");
  const [specificBuyer, setSpecificBuyert] = useState();
  const [method, setMethod] = useState("0");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(moment().format("HH:mm:ss"));
  const [endTime, setEndTime] = useState(moment().format("HH:mm:ss"));
  const [startPrice, setStartPrice] = useState(0);
  const [startFees, setStartFees] = useState(SERVICE_FEE);
  const [qty, setQty] = useState(1);
  const [ownerDetails, setOwnerDetails] = useState(null)
  const [networkPriceLogo, setNetworkPriceLogo] = useState("");
  // const [calendar,setCalendar]=useState(false)

  const [endPrice, setEndPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emptyPrice, setEmpty] = useState("");
  const [emptyQuantity, setQuantity] = useState("");
  const [fileType, setFileType] = useState(false);
  const [emptyEndPrice, setEndEmpty] = useState("");
  const [web3, setWeb3] = useState(new Web3(provider));
  const [metaweb3, setMetaWeb3] = useState(new Web3(Web3.givenProvider));
  const [networkList, setNetworkList] = useState([]);
  const [networkSelected, setNetworkSelected] = useState(null);
  const { getNetworkList } = useNetworks();
  const [chainid, setchainid] = useState();
  const [isSpecialContract, setIsSpecialContract] = useState(false);
  const [networkPriceUnit, setNetworkPriceUnit] = useState(null);
  const [options, setOptions] = useState([])
  const [previousListPrice, setPreviousListPrice] = useState(null)
  const [isReadOnlyPrice, setIsReadOnlyPrice] = useState(false)

  const history = useHistory();
  const { library } = useWeb3React();

  const { getNft, sellListing, ownerList, mintNft } = useNFT();
  const { transaction } = useComman();

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  // console.log('nftDetails=>', nftDetails);

  useEffect(() => {
    // fetchCollection();
    getNftDetails();
    getOwnerList();
    handelDuration("1_day");
    fetchNetwork();
    checkNetwork();
  }, [islogin, auth]);

  useEffect(() => {
    if ( nftDetails && Object.keys(nftDetails).length ) {
      setFileType(nftDetails.file_extension);
      setNetworkPriceUnit(() => nftDetails.network === 'binance' ? 'bnb' : nftDetails.network)
      setIsSpecialContract(() => nftDetails.contract_address.toLowerCase() === CONTRACT_ADDRESS_SPECIAL.toLowerCase())
      setOptions([
        { value: (nftDetails?.network), label: (nftDetails?.network) },
      ])
      // IsNftOwner(nft.owner_id);

      if (nftDetails["network"] == "binance") {
        setNetworkPriceLogo(binancePriceImage);
      } else if (nftDetails["network"] == "polygon") {
        setNetworkPriceLogo(maticPriceImage);
      } else if (nftDetails["network"] == "ethereum") {
        setNetworkPriceLogo(ethereumPriceImage);
      }

    }
  }, [nftDetails])

  useEffect(async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    setchainid(chainId);
  }, []);

  useEffect(() => {
    if ( ownerDetails && nftDetails && Object.keys(ownerDetails).length && Object.keys(nftDetails).length ) {
      const crntuser = auth?.user;
      if (crntuser && crntuser.wallet_address && crntuser.wallet_address.toLowerCase() == nftDetails.user_wallet.toLowerCase() && !ownerDetails.is_minted && nftDetails['enable_lazy_minting']) {
        checkAlreadyListedForSale();
      }
    }
  }, [ownerDetails, nftDetails])

  const fetchNetwork = async () => {
    const fetchNetwork = await getNetworkList();
    setNetworkList(fetchNetwork.networks);
    if (fetchNetwork.networks.length) {
      setNetworkSelected(fetchNetwork.networks[0]["_id"]);
    }
  };
  const checkNetwork = async () => {
    if (window.ethereum) {
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });

      // return true if network id is the same
      // if (currentChainId == targetNetworkId) console.log("sahi hai")
      // // return false is network id is different
      // console.log("galat hai")
    }
  };

  const getOwnerList = async () => {
    const crntuser = auth?.user;
    let urlNetwork = network;
    const ownerDetails = await ownerList(urlNetwork, contract_address, token_id)
    const nftOwner = ownerDetails?.owner_list.filter(e => e['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase()) || []
    if ( nftOwner && nftOwner.length && crntuser?.wallet_address == nftOwner[0]['wallet_address']) {
      setOwnerDetails(nftOwner[0])
    }/* else {
      history.push("/assets/"+ nftDetails.chain_name + '/' + contract_address + "/" + token_id);
    }*/
  }

  const getNftDetails = async () => {
    await getNft({ network, contract_address, token_id });
  };

  const handelDuration = (day) => {
    const today = moment().format("YYYY-MM-DD HH:mm:ss");
    if (day === "1_day") {
      setDuration(day);
      // const today = moment().format("YYYY-MM-DD");
      const nextDay = moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(new Date(today));
      setEndDate(new Date(nextDay));
      return;
    } else if (day === "3_day") {
      setDuration(day);
      // const today = moment().format("YYYY-MM-DD");
      const nextDay = moment().add(3, "days").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(new Date(today));
      setEndDate(new Date(nextDay));
      return;
    } else if (day === "7_day") {
      setDuration(day);
      // const today = moment().format("YYYY-MM-DD");
      const nextDay = moment().add(7, "days").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(new Date(today));
      setEndDate(new Date(nextDay));
      return;
    } else if (day === "1_month") {
      setDuration(day);
      // const today = moment().format("YYYY-MM-DD");
      const nextDay = moment().add(1, "months").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(new Date(today));
      setEndDate(new Date(nextDay));
      return;
    } else if (day === "3_month") {
      setDuration(day);
      // const today = moment().format("YYYY-MM-DD");
      const nextDay = moment().add(3, "months").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(new Date(today));
      setEndDate(new Date(nextDay));
      return;
    } else if (day === "6_month") {
      setDuration(day);
      // const today = moment().format("YYYY-MM-DD");
      const nextDay = moment().add(6, "months").format("YYYY-MM-DD HH:mm:ss");
      setStartDate(new Date(today));
      setEndDate(new Date(nextDay));
      return;
    } else {
      setDuration("custom");
      return;
    }
    // return;
    /*const date = new Date();
    const dateDay = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const start = `${year}-${month}-${dateDay} ${hour}:${minute}:${second}`;

    const end = `${year}-${month}-${
      dateDay + parseInt(day)
    } ${hour}:${minute}:${second} `;

    setEndDate(Date(end));
    setStartDate(Date(start));*/
  };

  const nextMont = () => {
    const date = new Date();
    const dateDay = date.getDate();
    const month = date.getMonth() + 2;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const end = `${year}-${month}-${dateDay} ${hour}:${minute}:${second} `;
    return new Date(end);
  };

  const selectCustomDate_bak = (newdate, type) => {
    const today = new Date();
    const todayFormat = moment(today).format("YYYY-MM-DD");
    var date = moment(newdate).format("YYYY-MM-DD");
    if (type === "start") {
      setStartDate(date);
    } else {
      setStartDate(todayFormat);
      setEndDate(date);
    }
  };

  const selectCustomDate = (newdate, type) => {
    console.log('selectCustomDate() newdate : ', newdate, ' type: ', type)
    var date = moment(newdate).format("YYYY-MM-DD HH:mm:ss");
    var durationDays = 0,
      durationMonths = 0;
    if (type === "start") {
      setStartDate(new Date(date));
      durationDays = moment(endDate).diff(date, "days");
      durationMonths = moment(endDate).diff(date, "months");
    } else {
      setEndDate(new Date(date));
      durationDays = moment(date).diff(moment(startDate), "days");
      durationMonths = moment(date).diff(moment(startDate), "months");
    }
    console.log('selectCustomDate() date: ', date, ' ', new Date(date))

    if (durationDays === 1) {
      setDuration("1_day");
      return;
    } else if (durationDays === 3) {
      setDuration("3_day");
      return;
    } else if (durationDays === 7) {
      setDuration("7_day");
      return;
    } else if (durationMonths === 1) {
      setDuration("1_month");
      return;
    } else if (durationMonths === 3) {
      setDuration("3_month");
      return;
    } else if (durationMonths === 6) {
      setDuration("6_month");
      return;
    } else {
      setDuration("custom");
      return;
    }
  };

  // stringCapitalize(nftDetails?.network)

  const countDown = (sdate, edate) => {
    var start = new Date(sdate);
    var end = new Date(edate);
    var state = { startDate: start.getTime(), timeEnd: end.getTime() }; // example

    const startDate = moment(state.startDate);
    const timeEnd = moment(state.timeEnd);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    return diffDuration.days();
  };

  const completeListing = async () => {
    setEmpty("");
    setEndEmpty("");
    setQuantity("")
    var sdate = "";
    var edate = "";
    let today = moment().format("YYYY-MM-DD HH:mm:ss");

    if ( !ownerDetails ) {
      notification.open({
        message: "Error",
        description: "You do not own this item!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }
    if ( qty > (ownerDetails?.quantity - ownerDetails?.revealed_count) ) {
      setQuantity("You don't have enough quantity!")
      return;
    }

    // console.log('startPrice: ', startPrice)
    if ( qty <= 0 ) {
      setQuantity("Please enter valid quantity!")
      return;
    }
    if (type === "AUCTION") {
      if (startPrice <= 0) {
        setEmpty("Please enter valid price!");
        return;
      }
      if (method !== "1" && endPrice === 0) {
        setEndEmpty("Please enter valid price!");
        return;
      }
      if (method !== "1" && startPrice <= endPrice) {
        setEndEmpty("Ending price must be less than starting price!");
        return;
      }
    } else {
      if (startPrice <= 0) {
        setEmpty("Please enter valid price!");
        return;
      }
    }

    let method_name;
    if (type === "FIXED") {
      method_name = "fixed";
    } else if (method === "1") {
      method_name = "sell_to_highest_bidder";
    } else if (method === "0") {
      method_name = "sell_with_declining_price";
    }

    if (startTime) {
      var newStartDate = moment(startDate).format("YYYY-MM-DD") + " " + startTime;
      sdate = moment(newStartDate).utc()//.format("YYYY-MM-DD HH:mm:ss");
      if (endDate && endDate !== null && endDate !== undefined) {
        var newEndDate = moment(endDate).format("YYYY-MM-DD") + " " + endTime;
        edate = moment(newEndDate).utc()//.format("YYYY-MM-DD HH:mm:ss");
      } else {
        // console.log('end date undefine, null');
        if (duration === '1_day') {
          const nextDay = moment().add(1, "days").format("YYYY-MM-DD HH:mm:ss");
          edate = moment(nextDay).utc();
          setStartDate(new Date(today));
          setEndDate(new Date(nextDay));  
        } else if(duration === '3_day') {
          const nextDay = moment().add(3, "days").format("YYYY-MM-DD HH:mm:ss");
          edate = moment(nextDay).utc();

          setStartDate(new Date(today));
          setEndDate(new Date(nextDay));
  
        } else if(duration === '7_day') {
          const nextDay = moment().add(3, "days").format("YYYY-MM-DD HH:mm:ss");
          edate = moment(nextDay).utc();

          setStartDate(new Date(today));
          setEndDate(new Date(nextDay));
  
        } else if(duration === '1_month') {
          const nextDay = moment().add(1, "months").format("YYYY-MM-DD HH:mm:ss");
          edate = moment(nextDay).utc();

          setStartDate(new Date(today));
          setEndDate(new Date(nextDay));
  
        } else if(duration === '3_month') {
          const nextDay = moment().add(3, "months").format("YYYY-MM-DD HH:mm:ss");
          edate = moment(nextDay).utc();
          setStartDate(new Date(today));
          setEndDate(new Date(nextDay));
        } else if(duration === '6_month' || duration === 'custom') {
          const nextDay = moment().add(6, "months").format("YYYY-MM-DD HH:mm:ss");
          edate = moment(nextDay).utc();
          setStartDate(new Date(today));
          setEndDate(new Date(nextDay));
        }
      }
    }

    console.log('completeListing() startDate: ', startDate, ' startTime: ', startTime, ' sdate: ', sdate, ' endDate: ', endDate, ' endTime: ', endTime, ' edate: ', edate)
    
    // console.log('duration', duration);
    // console.log('start-date', startDate, sdate, 'time', startTime );
    // console.log('end-date', endDate, edate, 'etime',endTime );


    const todayDateTime = moment().utc().startOf('day').format("YYYY-MM-DDTHH:mm:ssZ");
    if ( todayDateTime > sdate ) {
      notification.open({
        message: "Error",
        description: "Start date should be future date!!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    } else if ( moment(edate).diff(sdate, 'days') < 1 ) {
      notification.open({
        message: "Error",
        description: "Start date should not be less than end date!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    } else if ( todayDateTime > edate ) {
      notification.open({
        message: "Error",
        description: "End date should be future date!",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }

    try {
      setLoading(true);
      var CreatNewSale = null, nftAuction = null;
      if (type === "FIXED") {
          if ( ownerDetails.is_minted ) {
              CreatNewSale = await web3Sell(true, false, false, false);
              if (!CreatNewSale) {
                setLoading(false);
                return
              }
              nftAuction = CreatNewSale && CreatNewSale?.events?.NftDataCreated || null;
          } else {
            if ( ownerDetails['wallet_address'] === nftDetails['user_wallet'] ) {
              if ( nftDetails['enable_lazy_minting'] ) {
                if ( previousListPrice && startPrice !== previousListPrice ) {
                  CreatNewSale = await web3Sell(true, false, false, true);
                  if (!CreatNewSale) {
                    setLoading(false);
                    return
                  } else {
                    nftAuction = CreatNewSale && CreatNewSale?.events?.NftDataCreated || null;
                  }
                } else if ( previousListPrice && startPrice === previousListPrice ) {
                  
                } else {
                  CreatNewSale = await web3Sell(false, true, false, false);
                  if (!CreatNewSale) {
                    setLoading(false);
                    return
                  } else {
                    nftAuction = CreatNewSale && CreatNewSale?.events?.NftDataCreated || null;
                  }
                }
              } else {
                CreatNewSale = await web3Sell(true, false, true, false);
                if (!CreatNewSale) {
                  setLoading(false);
                  return
                } else {
                  nftAuction = CreatNewSale && CreatNewSale?.events?.NftDataCreated || null;
                }
              }
            } else {
              setLoading(false);
              return
            }
          }
      }
      else if (type === "AUCTION") {
        CreatNewSale = await web3Sell(true, false, true, false);
        if (!CreatNewSale) {
          setLoading(false);
          return
        } else {
          nftAuction = CreatNewSale && CreatNewSale?.events?.NftDataCreated || null;
        }
      }

      // if ( ownerDetails.is_minted ) {
      if ( nftAuction ) {
        /*CreatNewSale = await web3Sell();
        if (!CreatNewSale) {
          setLoading(false);
          return
        }
        nftAuction = CreatNewSale && CreatNewSale?.events?.NftDataCreated || null;*/
        const contractNetwork = nftDetails["network"] + "_" + nftDetails["network_chain_id"];
        let dataTransaction = {
          saller_id: nftDetails.owner_id,
          nft_id: nftDetails._id,
          buyer_id: undefined,
          event: type === "FIXED" ? "Listed" : "Auction",
          price: startPrice,
          date: new Date(),
          url: NetworkURLs[contractNetwork]['scanUrl'] + "/tx/",
          tnxHasId: nftAuction && nftAuction.transactionHash || null,
        };
        let transtion = await transaction(dataTransaction);
      }
      
      
      let data = {
        // type: type,
        start_date: sdate, //moment(sdate).toDate(),
        end_date: edate, //moment(edate).toDate(),
        start_price: startPrice.toString(),
        end_price: endPrice.toString(),
        price_unit: networkPriceUnit,
        supply_to_sale: qty,
        service_fee: nftDetails?.collection_id?.service_fee,
        method: method_name,
        tnxHasId: nftAuction && nftAuction.transactionHash || null,
      };

      const res = await sellListing(data, network, nftDetails.contract_address, nftDetails.token_id);
      if (res) {
        notification.open({
          message: "NFT",
          description: "NFT Is Ready For Sell...!",
          icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        });
        setLoading(false);
        history.push("/assets/"+ nftDetails.chain_name + '/' + contract_address + "/" + token_id);
      } else {
        setLoading(false);
        notification.open({
          message: "Error",
          description: "Something went wrong...!",
          icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        });
      }
    } catch (error) {
      console.log('error: ', error)
      setLoading(false);
      notification.open({
        message: "NFT",
        description: "Cancel the transaction!",
        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
      });
      return;
    }
  };

  const web3Sell = async (sellNFTOnMarket, initiateToken, initiateTokenMint, createToken) => {
    const web3 = new Web3(library.provider);
    let itemChainId =
      nftDetails && nftDetails.network_chain_id
        ? nftDetails.network_chain_id
        : null;
    if (!itemChainId) {
      notification.open({
        message: "Error",
        description: "Invalid nft chain id",
        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }
    let currentChainId = await web3.eth.getChainId();
    if (itemChainId !== currentChainId) {
      switchNetwork(String(itemChainId), library).then(() => completeListing());
      return;
    }

    var CreatNewSale = null;
    const user = auth?.user;
    const userAdd = user.wallet_address;
    let buyNowPrice = startPrice;
    const tokenId = nftDetails.token_id;
    const collectionDetails = nftDetails.collection_id;
    const validPayout = collectionDetails['payout'].filter(e => e['wallet_address']);
    const payoutAddresses = validPayout.map(e => e['wallet_address'])
    const percentageFees = validPayout.map(e =>  e['percentage'] ? +e['percentage'] * 100 : 0)
    const metadataURL = METADATA_BASE_URL + '/' + nftDetails["contract_address"] + '/' + nftDetails["token_id"]
    const issuedChildQuantity = +nftDetails.mystery_contents.content_issue_quantity || 0;
    const serviceFees = +nftDetails?.collection_id?.service_fee*100 || SERVICE_FEE*100;
    const pid = networkPriceUnit === 'busd' ? 0 : 99999;
    const ownerQuantity = ownerDetails?.quantity;

    try {
      const contractNetwork =
        nftDetails.network + "_" + nftDetails.network_chain_id;
      const abiMarketplace =
        nftDetails.token_standard === TokenStandard[1]
          ? abi_marketplace_1155
          : abi_marketplace_721;
      const abiMint =
        nftDetails.token_standard === TokenStandard[1]
          ? (isSpecialContract ? abi_mint_1155_special : abi_mint_1155)
          : abi_mint_721;
      const marketPlaceAddress =
        nftDetails.token_standard == TokenStandard[1]
          ? address["1155"][contractNetwork]["marketplace_address"]
          : address["721"][contractNetwork]["marketplace_address"];

      const contractAddress =
        nftDetails.token_standard == TokenStandard[1]
          ? (isSpecialContract ? CONTRACT_ADDRESS_SPECIAL : address["1155"][contractNetwork]["mint_address"])
          : address["721"][contractNetwork]["mint_address"];

      const connnectApprove = new web3.eth.Contract(abiMint, contractAddress);

      if ( initiateToken ) {
        CreatNewSale = null;
          ///initiateToken(uint256 tokenId, address owner, uint256 price, uint256 supply, address[] calldata _creators,
              ///uint32[] calldata creatorServiceFees, uint256 pId)
        if ( isSpecialContract ) {
          CreatNewSale = await connnectApprove.methods
          .registerBox(
            tokenId,
            issuedChildQuantity, 
            Web3.utils.toWei(`${buyNowPrice}`, "ether"),
            pid
          )
          .send({ from: userAdd });
        } else {
          CreatNewSale = await connnectApprove.methods
          .initiateToken(
            tokenId,
            userAdd, 
            Web3.utils.toWei(`${buyNowPrice}`, "ether"),
            ownerQuantity, //qty,
            payoutAddresses,
            percentageFees,
            serviceFees,
            pid
          )
          .send({ from: userAdd });
        }
      }
      if ( initiateTokenMint ) {
        CreatNewSale = null;
          //initiateTokenMint(uint256 tokenId, address owner, string calldata tokenURI, uint256 supply, address[] calldata _creators, 
          //uint32[] calldata creatorServiceFees, uint256 pId)
        if ( isSpecialContract ) {
          CreatNewSale = await connnectApprove.methods
          .registerBox(
            tokenId,
            issuedChildQuantity, 
            Web3.utils.toWei(`${buyNowPrice}`, "ether"),
            pid
          )
          .send({ from: userAdd });
        } else {
          CreatNewSale = await connnectApprove.methods
          .initiateTokenMint(
            tokenId,
            userAdd, 
            metadataURL,
            ownerQuantity, //qty,
            payoutAddresses,
            percentageFees,
            serviceFees,
            pid
          )
          .send({ from: userAdd });
        }
        if (CreatNewSale) {
          // await mintNft(network, contract_address, token_id);
          await mintNft(nftDetails['chain_name'], nftDetails.contract_address, nftDetails.token_id)
        }
      } 
      if ( createToken ) {
        CreatNewSale = null;
        /*if ( isSpecialContract ) {
          CreatNewSale = await connnectApprove.methods
          .registerBox(
            tokenId,
            issuedChildQuantity, 
            Web3.utils.toWei(`${buyNowPrice}`, "ether"),
            pid
          )
          .send({ from: userAdd });
        } else {*/
          CreatNewSale = await connnectApprove.methods
          .create(userAdd, token_id, '', ownerQuantity)
          .send({ from: userAdd });
        // }
        if (CreatNewSale) {
          await mintNft(nftDetails['chain_name'], nftDetails.contract_address, nftDetails.token_id)
        }
      }
      if ( sellNFTOnMarket ) {
        CreatNewSale = null;
        const isApprove = await connnectApprove.methods
          .isApprovedForAll(userAdd, marketPlaceAddress)
          .call({ from: userAdd });

        if (!isApprove) {
          await connnectApprove.methods
            .setApprovalForAll(marketPlaceAddress, true)
            .send({ from: userAdd });
        }

        const marketPlaceContract = new web3.eth.Contract(
          abiMarketplace,
          marketPlaceAddress
        );

        // function sellNFTOnMarket(address _nftContractAddress,uint256 _tokenId,uint256 _price,uint256 _serviceFees,address payable[] calldata _creators,uint16[] calldata _creatorFees,uint256 _qty,uint256 _pId)
        // console.log("method", method, buyNowPrice)
        if(type != "FIXED" && method == "0") { // set the price to end price so that the users can buy with less amount for declining auctions
          buyNowPrice = endPrice;
        }
        CreatNewSale = await marketPlaceContract.methods
          .sellNFTOnMarket(
            contractAddress,
            tokenId,
            Web3.utils.toWei(`${buyNowPrice}`, "ether"),
            Number(nftDetails?.collection_id?.service_fee),
            payoutAddresses,
            percentageFees,
            +qty,
            pid
          )
          .send({ from: userAdd });
      }
      return CreatNewSale;
    } catch (err) {
      console.log('err: ', err)
      notification.open({
        message: "NFT",
        description: "Transaction aborted!",
        icon: <SmileOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }
  };

  const checkAlreadyListedForSale = async ()  => {
    try {
      const web3 = new Web3(library.provider);

      let itemChainId = nftDetails && nftDetails.network_chain_id ? nftDetails.network_chain_id : null;
      if (!itemChainId) {
        notification.open({
          message: "Error",
          description: "Invalid nft chain id",
          icon: <CloseOutlined style={{ color: "#f4364c" }} />,
        });
        return;
      }
      let currentChainId = await web3.eth.getChainId();
      if (itemChainId !== currentChainId) {
        switchNetwork(String(itemChainId), library).then(() => completeListing());
        return;
      }

      const contractNetwork = nftDetails.network + "_" + nftDetails.network_chain_id;
      const abiMint =
        nftDetails.token_standard === TokenStandard[1]
          ? (isSpecialContract ? abi_mint_1155_special : abi_mint_1155)
          : abi_mint_721;
      const contractAddress =
          nftDetails.token_standard == TokenStandard[1]
            ? (isSpecialContract ? CONTRACT_ADDRESS_SPECIAL : address["1155"][contractNetwork]["mint_address"])
            : address["721"][contractNetwork]["mint_address"];
      const contractInit = new web3.eth.Contract(abiMint, contractAddress);
      var initialPrice = await contractInit.methods.initialPrice(nftDetails['token_id']).call()
      // console.log('initialPrice: ', initialPrice)
      if ( initialPrice == "0" ) {
        setPreviousListPrice(null)
        setIsReadOnlyPrice(false)
      } else {
        const etherValue = Web3.utils.fromWei(initialPrice, 'ether');
        setPreviousListPrice(etherValue)
        setIsReadOnlyPrice(true)
        startPriceRef.current.value = etherValue
        setStartPrice(etherValue)
      }
      
    } catch (err) {
      console.log('1. err: ', err)
      notification.open({
        message: "NFT",
        description: "Transaction aborted!",
        icon: <SmileOutlined style={{ color: "#f4364c" }} />,
      });
      return;
    }
  }

  const updatePriceConfirmation = () => {
    confirm({
      title: 'Are you sure to update the price?',
      content: 'Are you sure you want to change the Initial Price?',
      onOk() {
        setIsReadOnlyPrice(false)
      },
      onCancel() {
        
      },
    });
  }

  const auction = () => {
    return (
      <div className="sellforinputs">
        <div className="sellsinouts-item mb-4 mt-5">
          <h3 className="mb-2">METHOD</h3>
          <div className="d-flex gap-10 two-number-inputs cstom-select-box">
            <select
              className="no-right-borderR"
              name="type"
              onChange={(event) => setMethod(event.target.value)}
            >
              {/* <option value={"1"}>Sell to highest bidder</option> */}
              <option value={"0"}>Sell with declining price</option>
            </select>
          </div>
        </div>
        {
          nftDetails?.network == 'binance' && isSpecialContract ?
            <div className="sellsinouts-item mb-4">
            <h3 className="mb-2">BINANCE PRICE UNIT</h3>
              <div className="two-number-inputs">
                <div className="row reverse-row">
                  <div className="col-12 show-price-wrap">
                      <select className="no-right-borderR" name="price_unit" onChange={(event) => setNetworkPriceUnit(event.target.value)}>
                          <option value="bnb">BNB</option>
                          <option value="busd">BUSD</option>
                      </select>
                  </div>
                </div>
              </div>
            </div> : ''
        }
        <div className="sellsinouts-item mb-4 mt-5">
          <h3 className="mb-2">START PRICE</h3>
          <div className="two-number-inputs">
            {/* <select className="no-right-borderR" name="type">
                <option value={nftDetails?.network}>
                  {nftDetails?.network_chain_label}
                </option>
            </select> */}
            <div className="row reverse-row">
              {/* <div className="col-1 pl-0 price-list"> */}
                {/* <Select
                  getOptionLabel={(opt) => (
                    <div className="d-flex align-items-center">
                      // { <img src={chainImageHandler(opt?.value)} height="35px" width="35px" /> }
                      <span className={ opt?.value+"-icon d-block ml-2"}>
                        {stringCapitalize(opt?.value)}
                      </span>
                    </div>
                  )}
                  getOptionValue={option => option.value}
                  options={options}
                /> */}
                <>
                {/* <em className={`${nftDetails?.network}-icon`}></em> */}
                {/* <img src={chainImageHandler(nftDetails?.network)} height="35px" width="35px" />
                <span>{nftDetails?.network.toUpperCase()}</span> */}
                
                {/* <select className="no-right-borderR" name="type">
                    <option value={nftDetails?.network}>
                      {nftDetails?.network}
                    </option>
                </select> */}
                </>
              {/* </div> */}
              <div className="col-12 show-price-wrap">
                <em className={`${nftDetails?.network}-icon`}></em>
                <input
                  onChange={(event) => setStartPrice(event.target.value)}
                  className="no-left-borderR"
                  type="number"
                  placeholder="AMOUNT"
                  onWheel={(e) => e.target.blur()}
                />
              </div>
            </div>
            {/* <input
              onChange={(event) => setStartPrice(event.target.value)}
              className="no-left-borderR w-50"
              type="number"
              placeholder="AMOUNT"
            /> */}
          </div>
          <div className="text-danger">{emptyPrice}</div>
        </div>
        <div className="sellsinouts-item mb-4">
          <h3 className="mb-2">DURATION</h3>
          <div className="d-flex gap-0 single-number-inputs">
            <select onChange={(event) => handelDuration(event.target.value)}>
              <option value="1_day">1 Day</option>
              <option value="3_day">3 Day</option>
              <option value="7_day">7 Day</option>
              <option value="1_month">1 Month</option>
              <option value="3_month">3 Month</option>
              <option value="6_month">6 Month</option>
              <option value="custom">Custom</option>
            </select>
          </div>
        </div>
        {method === "0" ? (
          <div className="sellsinouts-item mb-4 mt-5">
            <h3 className="mb-2">END PRICE</h3>
            <div className="two-number-inputs">
              <div className="row reverse-row">
                {/* <div className="col-1 pl-0 price-list">
                  <em className={`${nftDetails?.network}-icon`}></em> */}
                  {/* <img src={chainImageHandler(nftDetails?.network)} height="35px" width="35px" />
                  <span>{nftDetails?.network.toUpperCase()}</span> */}
                  {/* <select className="no-right-borderR" name="type">
                        <option value={nftDetails?.network}>
                          {nftDetails?.network}
                        </option>
                  </select> */}
                {/* </div> */}
                <div className="col-12 show-price-wrap">
                <em className={`${nftDetails?.network}-icon`}></em>
                  <input
                    onChange={(event) => setEndPrice(event.target.value)}
                    className="no-left-borderR"
                    type="number"
                    placeholder="AMOUNT"
                  />
                </div>
              </div>
            </div>
            <div className="text-danger">{emptyEndPrice}</div>
          </div>
        ) : (
          ""
        )}
        {duration ? (
          <div className="sellsinouts-item bg-border-box mt-5">
            <div className="sellsinouts-inner d-flex justify-content-evenly align-items-center w-100">
              <img src={CalenderIcon} />
              <div className="d-flex gap-0 single-number-inputs">
                <div className="flex-column">
                  <h3>Starting </h3>
                  <DatePicker
                    value={startDate}
                    minDate={new Date()}
                    className="no-left-borderR"
                    onChange={(value) => selectCustomDate(value, "start")}
                  />
                </div>
                <div className="flex-column">
                  <h3>Ending</h3>
                  <DatePicker
                    value={endDate}
                    minDate={new Date()}
                    className="no-left-borderR"
                    onChange={(value) => selectCustomDate(value, "end")}
                  />
                </div>
              </div>
            </div>
            <div className="sellsinouts-inner d-flex justify-content-evenly align-items-center mt-2 w-100">
              <img src={WatchIcon} />
              <div className="d-flex gap-0 single-number-inputs">
                <div className="flex-column">
                  <h3 className="fix-timepicker">From</h3>
                  <TimePicker
                    allowClear={false}
                    defaultValue={moment(startTime, "HH:mm:ss")}
                    onChange={(time) => setStartTime(time.format("HH:mm:ss"))}
                  />
                </div>
                <div className="flex-column">
                  <h3 className="fix-timepicker">To</h3>
                  <TimePicker
                    allowClear={false}
                    defaultValue={moment(endTime, "HH:mm:ss")}
                    onChange={(time) => setEndTime(time.format("HH:mm:ss"))}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  // console.log("select",duration)
  return (
    <div className="container lists-step-page form-transp-bg">
      <div className="row">
        <div className="col-12">
          <h3 className="oracleTextClass" style={{}}>
            {type} PRICE
          </h3>
          <div className="row mt-2">
            <div className="col-6 p-0 pr-3">
              {
                nftDetails?.supply == 1 ?
                <div className="d-flex gap-10 edit-sell-bvtns tab-feel">
                  <a
                    className={type === "FIXED" ? "active" : ""}
                    onClick={() => setType("FIXED")}
                  >
                    FIXED PRICE
                  </a>
                  <a
                    className={type === "AUCTION" ? "active" : ""}
                    onClick={() => setType("AUCTION")}
                  >
                    AUCTION{" "}
                  </a>
                </div> : ''
              }
              
              {type === "FIXED" ? (
                <div className="sellforinputs">
                  {
                    nftDetails?.supply != 1 ?
                    (
                      <div className="sellsinouts-item mb-4">
                        <h3 className="mb-2">Quantity</h3>
                        <div className="d-flex gap-10 two-number-inputs">
                            <input
                              defaultValue={qty}
                              onChange={(event) => setQty(event.target.value)}
                              className="no-left-borderR"
                              type="number"
                              placeholder="Enter Quantity"
                              onWheel={(e) => e.target.blur()}
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="text-danger">{emptyQuantity}</span>
                          <span>{ownerDetails?.quantity - ownerDetails?.revealed_count} available</span>
                        </div>
                      </div>
                    ) : ''
                  }
                  {/* <div className="sellsinouts-item mb-4 mt-5">
                    <h3 className="mb-2">Quantity</h3>
                    <div className="d-flex gap-10 two-number-inputs">
                      {nftDetails?.token_standard === TokenStandard[1] ? (
                        <input
                          onChange={(event) => setQty(event.target.value)}
                          className="no-left-borderR"
                          type="number"
                          placeholder="Enter Quantity"
                        />
                      ) : (
                        <input
                          // onChange={(event) => setQty(event.target.value)}
                          className="no-left-borderR"
                          type="number"
                          value={qty}
                          disabled={true}
                          // placeholder="1"
                        />
                      )}
                      
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="text-danger">{emptyQuantity}</span>
                      <span>{nftDetails?.supply} available</span>
                    </div>
                  </div> */}
                  {
                    nftDetails?.network == 'binance' && isSpecialContract ?
                      <div className="sellsinouts-item mb-4">
                      <h3 className="mb-2">BINANCE PRICE UNIT</h3>
                        <div className="two-number-inputs">
                          <div className="row reverse-row">
                            <div className="col-12 show-price-wrap">
                                <select className="no-right-borderR" name="price_unit" onChange={(event) => setNetworkPriceUnit(event.target.value)}>
                                    <option value="bnb">BNB</option>
                                    <option value="busd">BUSD</option>
                                </select>
                            </div>
                          </div>
                        </div>
                      </div> : ''
                  }
                  
                  <div className="sellsinouts-item mb-4">
                    <h3 className="mb-2">PRICE</h3>
                    <div className="two-number-inputs">
                      {/* <select className="no-right-borderR" name="type">
                        <option value={nftDetails?.network}>
                          {stringCapitalize(nftDetails?.network)}
                        </option>
                      </select> */}
                      {/* <input
                        onChange={(event) => setStartPrice(event.target.value)}
                        className="no-left-borderR"
                        type="number"
                        placeholder="AMOUNT"
                      /> */}
                        <div className="row reverse-row">
                        {/* <div className="col-1 pl-0 price-list"> */}
                          {/* <Select
                            getOptionLabel={(opt) => (
                              <div className="d-flex align-items-center">
                                // { <img src={chainImageHandler(opt?.value)} height="35px" width="35px" /> }
                                <span className={ opt?.value+"-icon d-block ml-2"}>
                                  {stringCapitalize(opt?.value)}
                                </span>
                              </div>
                            )}
                            getOptionValue={option => option.value}
                            options={options}
                          /> */}
                          
                        {/* </div> */}
                        <div className={isReadOnlyPrice ? "col-8 show-price-wrap" : "col-12 show-price-wrap"}>
                          <em className={`${nftDetails?.network}-icon`}></em>
                          <input
                            onChange={(event) => setStartPrice(event.target.value)}
                            className="no-left-borderR"
                            type="number"
                            placeholder="AMOUNT"
                            onWheel={(e) => e.target.blur()}
                            defaultValue={previousListPrice ? previousListPrice : 0}
                            readOnly={isReadOnlyPrice}
                            ref={startPriceRef}
                          />
                        </div>
                        {
                          isReadOnlyPrice ? 
                          <div className="col-4">
                            <button className="nft-sub-btn" type="button" onClick={updatePriceConfirmation}>
                              Update
                            </button>
                          </div> : ''
                        }
                      </div>
                    </div>
                    <div className="text-danger">{emptyPrice}</div>
                  </div>
                  <div className="sellsinouts-item mb-4">
                    <h3 className="mb-2">DURATION</h3>
                    <div className="d-flex gap-0 single-number-inputs">
                      <select
                        onChange={(event) => handelDuration(event.target.value)}
                        value={duration}
                      >
                        <option value="1_day">1 Day</option>
                        <option value="3_day">3 Day</option>
                        <option value="7_day">7 Day</option>
                        <option value="1_month">1 Month</option>
                        <option value="3_month">3 Month</option>
                        <option value="6_month">6 Month</option>
                        <option value="custom">Custom</option>
                      </select>
                    </div>
                  </div>
                  {duration ? (
                    <div className="sellsinouts-item bg-border-box mt-5">
                      <div className="sellsinouts-inner d-flex justify-content-evenly align-items-center w-100">
                        <img src={CalenderIcon} />
                        <div className="d-flex gap-0 single-number-inputs">
                          <div className="flex-column">
                            <h3>Starting </h3>
                            {/* <DatePicker
                                value={new Date()}
                                minDate={new Date()}
                                label="DatePicker Label"
                                onChange={value => setState({ date: value })}
                            /> */}
                            <DatePicker
                              value={startDate}
                              minDate={new Date()}
                              // maxDate={nextMont()}
                              className="no-left-borderR"
                              onChange={(value) =>
                                selectCustomDate(value, "start")
                              }
                            />
                          </div>
                          <div className="flex-column">
                            <h3>Ending</h3>
                            <DatePicker
                              value={endDate}
                              minDate={new Date()}
                              // maxDate={nextMont()}
                              className="no-left-borderR"
                              onChange={(value) =>
                                selectCustomDate(value, "end")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sellsinouts-inner d-flex justify-content-evenly align-items-center mt-2 w-100">
                        <img src={WatchIcon} />
                        <div className="d-flex gap-0 single-number-inputs">
                          <div className="flex-column">
                            <h3 className="fix-timepicker">From</h3>
                            <TimePicker
                              allowClear={false}
                              defaultValue={moment(startTime, "HH:mm:ss")}
                              onChange={(time) =>
                                setStartTime(time.format("HH:mm:ss"))
                              }
                            />
                          </div>
                          <div className="flex-column">
                            <h3 className="fix-timepicker">To</h3>
                            <TimePicker
                              allowClear={false}
                              defaultValue={moment(endTime, "HH:mm:ss")}
                              onChange={(time, timeString) =>
                                setEndTime(time.format("HH:mm:ss"))
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="sellsinouts-item mb-4 mt-5">
                    <div className="row">
                      <div className="col-10">
                        <h3 className="mb-2">FEES- Service Fees: </h3>
                      </div>
                      <div className="col-2 text-right">
                        {nftDetails?.collection_id?.service_fee}%
                      </div>
                    </div>
                    <div className="d-flex gap-10 two-number-inputs">
                      {/* <input
                        onChange={(event) => setStartFees(event.target.value)}
                        className="no-left-borderR"
                        type="number"
                        placeholder="FEES"
                      /> */}
                      {/* <input className="no-left-borderR" type="number" placeholder="FEES" readOnly={true} value={startFees} /> */}
                    </div>
                  </div>
                </div>
              ) : (
                auction()
              )}
            </div>
            <div className="col-6 p-0">
              <div className="create-two-preview-box d-flex justify-content-center align-items-center m-0 position-relative">
                <div className="w-100 preview-img-sell">
                  <label
                    className="custom-file-label-old see-img-full"
                    htmlFor="inputGroupFile01"
                  >
                    {nftDetails?.file_extension === "video" ? (
                      <video
                        playsInline
                        muted
                        autoPlay={true}
                        controlsList="nodownload"
                        loop=""
                        preload="auto"
                        src={nftDetails && nftDetails.item_resized_image ? nftDetails.item_resized_image : nftDetails.item_image}
                        style={{ width: "100%", height: "calc(100% - 50px" }}
                      ></video>
                    ) : (
                      ""
                    )}
                    {nftDetails?.file_extension === "image" ? (
                      <img
                        // src={
                        //   nftDetails
                        //     ? nftDetails.item_image
                        //     : "https://via.placeholder.com/80x121.png?text=Use"
                        // }
                        src={
                          nftDetails && nftDetails.item_resized_image 
                            ? nftDetails.item_resized_image : nftDetails.item_image                            
                        }
                        //: "https://via.placeholder.com/80x121.png?text=Use"
                      />
                    ) : (
                      ""
                    )}
                    {nftDetails?.file_extension === "audio" ? (
                      <audio controls>
                        <source src={nftDetails && nftDetails.item_resized_image ? nftDetails.item_resized_image : nftDetails.item_image} />
                      </audio>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
                <div className="preview-box-subheads  d-flex justify-content-between align-items-center position-absolute w-100 px-5">
                  <h3>
                    {nftDetails ? nftDetails.item_name : ""} <br />
                    {/* {nftDetails ? nftDetails.item_name : ""} */}
                    {nftDetails
                      ? nftDetails?.collection_id?.collection_name
                      : ""}
                  </h3>
                  {/* <h3>{nftDetails ? nftDetails.start_price : ""}</h3> */}
                  <div className="d-flex">
                    <ViewPrice network={nftDetails ? nftDetails.network : ""} price={startPrice} />
                    {/* <h3>{nftDetails ? nftDetails.network : ""}</h3> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="createstep2-btns my-5">
            <h3 onClick={() => (!loading && ownerDetails ? completeListing() : "")}>
              {!loading ? "COMPLETE" : "Please Wait..."}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

// const mapStateToProps = state => ({
//     islogin:state.auth.isLogin,
//     auth:state.auth.auth
// })

const mapStateToProps = (state) => {
  return {
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    nftDetails: state.NFTDetails?.nftDetails,
  };
};
export default connect(mapStateToProps)(ListForSalePageComponent);
