import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useParams, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';
import Scrollup from '../components/Scrollup/Scrollup';
import MakeOfferPopup from '../components/Modal/MakeOfferPopup'
import NftOwnerList from '../components/Modal/NftOwnerList'
import BuyNowNft from '../components/Modal/BuyNowNft'
import TransferNft from '../components/Modal/TransferNft'
import BidNft from '../components/Modal/BidNft'
import ViewUnlockableContent from '../components/Modal/ViewUnlockableContent'
import { useWeb3React } from "@web3-react/core";

import HammerIcon from '../image/nft-detail/hammer-icon.png'
import { NavLink } from 'react-router-dom';
import NftDetailImage from '../image/nft-detail/Whales_NFTdetailPage.png'
import transferImage from '../image/nft-detail/transfer.png'
import SelectArrow from '../image/SelectArrow.png'

import Grouprefresh from '../image/nft-detail/Grouprefresh.png'
import GroupshareSocial from '../image/nft-detail/GroupshareSocial.png'
import NftActivity from '../image/nft-detail/Groupnft-activity.png'
import GReport from '../image/nft-detail/GroupReport.png'
import currentTimeImage from '../image/nft-detail/current-time.png'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3';
import buyCard from '../image/nft-detail/buy-card.png'
import saleEnd from '../image/nft-detail/sale-end.png'
import binancePriceImage from '../image/networkPrice/binance.jpg'
import maticPriceImage from '../image/networkPrice/matic.jpg'
import ethereumPriceImage from '../image/networkPrice/ethereum.jpg'
import makeOffer from '../image/nft-detail/make-offer.png'
import walletButton from '../image/nft-detail/wallet-button.png'
import priceHistory from '../image/nft-detail/price-history.png'
import OffersImage from '../image/nft-detail/Offers.png'
import favImage from '../image/nft-detail/followImage.png'
import CountDownTimer from '../components/Timer/index'
import ItemPrice from '../components/ItemPrice/purchaseItemPrice'
import ViewPrice from '../components/ItemPrice/viewPrice'
import { Modal, Input, Checkbox, notification, Button } from 'antd'
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment'
import NFTDetailPageCreate from "../components/Skeletons/NFTDetailPageCreate";
import WalletAddress from '../components/WalletAddress/WalletAddress';

// import marketPlaceabi from "../abi/marketPlace.json"
import abi_mint_1155 from "../abi/ABI_Mint_1155.json";
import abi_mint_721 from "../abi/ABI_Mint_721.json";
import abi_busd_erc20 from "../abi/ABI_BUSD_ERC20.json";
import abi_marketplace_1155 from "../abi/ABI_MarketPlace_1155.json";
import abi_mint_1155_special from "../abi/ABI_Mint_1155_SPECIAL.json";
import abi_marketPlace_721 from "../abi/ABI_Marketplace_721.json";
import * as address from "../abi/address.json";
import { currentPrice, getPercentageAmount, switchNetwork } from '../utils/helper.js'
import PageNotFound from './PageNotFound';
import { TokenStandard, NetworkURLs, MetadataDisplayType, METADATA_BASE_URL, URLS, MethodName, CONTRACT_ADDRESS_SPECIAL, CONTRACT_ADDRESS_BUSD_ERC20 } from '../utils/app.constant'
import ShareContentModal from '../components/Modal/ShareContentModal';
import { concatHostUrl } from '../utils/helper';
import Pagination from '@mui/material/Pagination';

//custom hooks
import useNFT from '../hooks/useNFT';
import useBid from '../hooks/useBid';
import useActivity from '../hooks/useActivity';
import useComman from '../hooks/useComman';
import useUser from '../hooks/useAuth';
import useOffer from '../hooks/useOffers'
import useNetwork from '../hooks/useNetwork'
import LikeDislikeNft from '../components/LikeDislike/LikeDislikeNft';
import ReportModal from '../components/Report/ReportModal';
import UnlockContentModal from '../components/ItemDetails/UnlockContentModal';
import { CHAINS } from '../web3Setup/connectors';
import ReadMoreContent from '../components/Common/ReadMoreContent';
import { BurnNft } from '../components/Modal/BurnNft';

// const provider = new WalletConnectProvider({
//     rpc: {
//         56: "https://bsc-dataseed1.binance.org",
//         // 97:"https://data-seed-prebsc-1-s1.binance.org:8545/",
//     },
//     chainId: 56,
//     network: "binance",
//     qrcode: false,
//     qrcodeModalOptions: {
//         mobileLinks: ["metamask", "trust"],
//     },
// });


function NftDetailPage({ props, islogin, auth, nftDetails, isfetching, networkList }) {
    const { network: urlNetwork, contract_address, token_id } = useParams();
    const [nft, setNft] = useState({});
    const [networkPriceLogo, setNetworkPriceLogo] = useState("");
    const [walletVisibility, setwalletVisibility] = useState(false);
    const [offerVisibility, setOfferVisibility] = useState(false);
    const [bidVisibility, setBidVisibility] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [datetime, setDateTime] = useState(new Date())
    const [isCreator, setCreator] = useState(false)
    const [isOwner, setOwner] = useState(null)
    const [isPriceListingOwner, setPriceListingOwner] = useState(false)
    const [isSell, setSell] = useState(false)
    const [timer, setTimer] = useState(false)
    const [timeEnd, setTimeEnd] = useState(false)
    const [crntprice, setCrntPrice] = useState()
    // const [bidPrice, setBidPrice] = useState()
    // const [bidError, setBidError] = useState('')
    const [offertprice, setOfferPrice] = useState()
    const [expiration, setExpiration] = useState(0)
    const [nftActivities, setNftActivities] = useState({ totalActivities: 0, latestActivities: [], pageActivity: 1, last_page_activity: 0 })
    const [priceBids, setPriceBids] = useState({ totalBids: 0, bids: [], pageBid: 1, last_page_bid: 0 })
    const [nftOfferces, setNftOfferces] = useState(false)
    const [isloading, setIsloading] = useState(true)
    const [isBidOver, setBidOver] = useState(false)
    const [bidWinner, setBidWinner] = useState(false)
    const [isEditable, setEditable] = useState(false)
    const [priceListing, setPriceListing] = useState({})
    const [priceListingBuyNFT, setPriceListingBuyNFT] = useState({})
    const [collection, setCollection] = useState(false)
    const [loading, setLoading] = useState(false)
    const [nftFetching, setNftFetching] = useState(false)
    const [reportPopup, setReportPopup] = useState(false)
    const [ownerListPopup, setOwnerListPopup] = useState(false)
    const [totalLikeCount, setTotalLikeCount] = useState(0)
    const [showUnlockableContentModal, setShowUnlockableContentModal] = useState(false)
    const [reportError, setReportError] = useState('')
    const [currentBnbPrice, setBnbPrice] = useState(0)
    const [coinPrice, setCoinPrice] = useState(0)
    const ref = useRef(false)
    const [countTime, setCountTime] = useState(false)
    const [isOfferUser, setIsOfferUser] = useState(false)
    const [isActiveSellButton, setIsActiveSellButton] = useState(false)
    const [isOfferClosed, setIsOfferclosed] = useState(false)
    const [fileType, setFileType] = useState(false)
    const [showSensitiveCoverImg, setShowSensitiveCoverImg] = useState(false)
    const [nftUnlockableContent, setNftUnlockableContent] = useState({ loading: false, data: null, success: false })

    const [marketPlaceAbi, setMarketPlaceAbi] = useState(null)
    const [mintAbi, setMintAbi] = useState(null)
    const [marketPlaceAddress, setMarketPlaceAddress] = useState(null)
    const [mintAddress, setMintAddress] = useState(null)
    const [ownerListDetails, setOwnerListDetails] = useState(null)
    const [priceList, setPriceList] = useState(null)
    // const [activityList, setActivityList] = useState([])
    const [blurEffect, setBlurEffect] = useState(false)
    const [network, setNetwork] = useState(null)
    const [showShareModal, setShowShareModal] = useState(false)
    const [burnNftModal, setBurnNftModal] = useState(false);
    const history = useHistory()

    const { account, library } = useWeb3React();
    const { getNft, getNftDetails, update, saveReport, transferNFT, cancelPriceList, ownerList, buyListing, getPriceList, fetchNftUnlockableContent, burnSelectedNft } = useNFT()
    const { /*getWinnerOfBid,*/makeBid, cancelBid, acceptBid, getBidWithPagination } = useBid();
    const { getActivityList } = useActivity()

    const { transaction } = useComman()
    const { getUser } = useUser()
    const { getOfferFilter, create, offerUpdate } = useOffer()
    const { deleteNFT } = useNFT();
    const { getNetworkList } = useNetwork();
    const [reason, setReason] = useState("")
    const [textArea, setTextArea] = useState("")
    const [isMystery, setMystery] = useState(false)
    const [isSpecialContract, setIsSpecialContract] = useState(false);
    const [ nftMarketItemQuantity, setNftMarketItemQuantity ] = useState({attempt: 0, quantity: 0, quantity: 0})
    const unlockContentModalRef = useRef(null);
    const { confirm } = Modal;

    const handleChange = (event) => {
        setReason(event.target.value)
    }
    const handleComment = (event) => {
        setTextArea(event.target.value)
    }



    /*history.listen((location, action) => {
        fetchNftDetails(location.pathname.replace('/assets/',''))
    })*/

    useEffect(() => {
        ref.current = true
        return () => {
            ref.current = false
        }
    }, [])
    useEffect(() => {
        initAPIs();
    }, [islogin, contract_address, token_id])

    useEffect(() => {
        if (nftDetails && Object.keys(nftDetails).length) {
            setNft(nftDetails)
        }
    }, [nftDetails])

    useEffect(() => {
        updateLocalState()
    }, [nft, ownerListDetails, priceBids])

    useEffect(() => {
        updateStateNetwork()
    }, [nft, networkList])

    useEffect(() => {
        updateStatePriceList()
    }, [nft, priceList])

    useEffect(() => {
        var crntuser = auth?.user;
        if (nft && Object.keys(nft).length && marketPlaceAbi && marketPlaceAddress && library && library.provider && crntuser) {
            const userPriceList = priceList && priceList.filter(e => e['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase() && e['status'] == 'active')

            if ( (!userPriceList || (userPriceList && !userPriceList.length)) && nftMarketItemQuantity['attempt'] == 0 ) {
                nftMarketItems(crntuser.wallet_address)
            } else {
                setNftMarketItemQuantity({attempt: 0, quantity: 0, quantity: 0});
            }
        }
    }, [nft, priceList, marketPlaceAbi, marketPlaceAddress, library])

    useEffect(() => {
        fetchBids(priceBids.pageBid || 1)
    }, [priceListing])

    const initAPIs = () => {
        fetchNftDetails()
        getNetworks()
        getOwnerList();
        fetchPriceList();
        fetchActivityList(nftActivities.pageActivity || 1);
        fetchBids(priceBids.pageBid || 1)
    }
    const walletShowhide = () => {
        if (offerVisibility === false) {
            setOfferVisibility(true);
        } else {
            setOfferVisibility(false);
        }
    }

    const handelDuration = (day) => {
        const date = new Date()
        const dateDay = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        const hour = date.getHours()
        const minute = date.getMinutes()
        const second = date.getSeconds()
        const start = `${year}-${month}-${day} ${hour}:${minute}:${second}`
        const end = `${year}-${month}-${dateDay + parseInt(day)} ${hour}:${minute}:${second} `
        return { start, end }
    }
    const setCurrentPrice = async (data) => {
        var res = await currentPrice()
        setCoinPrice(res)
        if (crntprice) {
            await setBnbPrice(parseFloat(res * crntprice).toFixed(2))
        }
    }
    const arrangeDate = (Date) => {
        const date = Date
        const dateDay = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()
        const hour = date.getHours()
        const minute = date.getMinutes()
        const second = date.getSeconds()
        const startDate = `${year}-${month}-${dateDay} ${hour}:${minute}:${second}`
        return startDate
    }

    const fetchNftDetails = async () => {
        if (auth?.user) {
            setNftFetching(true);
            // console.log('network', urlNetwork);
            const nft = await getNftDetails({ network: urlNetwork, contract_address, token_id })
            if (!nft) {
                setNft({})
            } else {
            setNftFetching(false);
            setNft(nft['nft_items'])
            }
        } else {
            await getNft({network: urlNetwork, contract_address, token_id })
            // setNft(nft['nft_items'])
        }
    }

    const getOwnerList = async () => {
        console.log('getownerlist', urlNetwork);
        const ownerDetails = await ownerList( urlNetwork, contract_address, token_id)
        setOwnerListDetails(ownerDetails)
    }

    const fetchPriceList = async () => {
        const respPriceList = await getPriceList(urlNetwork, contract_address, token_id)
        setPriceList(respPriceList)
    }

    const fetchActivityList = async (page) => {
        var { total: totalActivities, latestActivities, page: pageActivity, last_page: last_page_activity } = await getActivityList({ urlNetwork , contract_address, token_id }, { page })
        setNftActivities({ totalActivities, latestActivities, pageActivity, last_page_activity })
    }

    const fetchBids = async (page) => {
        if (priceListing && Object.keys(priceListing).length) {
            var { total: totalBids, bids, page: pageBid, last_page: last_page_bid } = await getBidWithPagination({ price_id: priceListing['_id'], status: 'pending', page })
            setPriceBids({ totalBids, bids, pageBid, last_page_bid })
        } else {
            if (priceBids['bids'].length) {
                setPriceBids({ totalBids: 0, bids: [], pageBid: 1, last_page_bid: 0 })
            }
        }
    }

    const getNetworks = async () => {
        await getNetworkList()
    }
    const updateStatePriceList = () => {
        var crntuser = auth?.user
        const priceListing = priceList && priceList[0] || {};
        const price = priceListing && priceListing.method ?
            priceListing.method == MethodName[0] ? priceListing.start_price :
                priceListing.highest_bid_price || priceListing.start_price : 0;
        const priceEndDate = priceListing && priceListing.end_date && moment(priceListing.end_date).local().format("YYYY-MM-DD HH:mm:ss") || null

        if (crntuser) {
            setPriceListingOwner(() => priceListing && priceListing['wallet_address'] && priceListing['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase())

            const userPriceList = priceList && priceList.filter(e => e['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase() && e['status'] == 'active')

            if (nft && Object.keys(nft).length) {
                if ( userPriceList && userPriceList.length ) {
                    setIsActiveSellButton(false);
                } else {
                    setIsActiveSellButton(true);
                    // nftMarketItems(crntuser.wallet_address)
                }
                    /*setIsActiveSellButton(() => {
                        if (nft.supply > 1) {
                            return true;
                        } else if (!Object.keys(priceListing).length) {
                            return true;
                        } else {
                            return false;
                        }
                    })*/
            }

        }

        setSell(priceListing && priceListing.ready_for_sell ? true : false)
        setPriceListing(priceListing)
        setCrntPrice(price)
        setTimer(priceEndDate)
        countDown(priceEndDate)
    }
    const updateStateNetwork = () => {
        if (nft && Object.keys(nft).length) {
            const network = networkList.length && networkList.find(e => e['network'] == nft['network'] && +e['chain_id'] == +nft['network_chain_id']) || null;
            setNetwork(network);

            if (network && network['network'] == 'binance') {
                setNetworkPriceLogo(binancePriceImage);
            } else if (network && network['network'] == 'polygon') {
                setNetworkPriceLogo(maticPriceImage);
            } else if (network && network['network'] == 'ethereum') {
                setNetworkPriceLogo(ethereumPriceImage);
            }

            const contractNetwork = nft['network'] + '_' + nft['network_chain_id']
            if (nft['token_standard'] == TokenStandard[0]) { // 721
                setMarketPlaceAddress(address["721"][contractNetwork]['marketplace_address'])
                setMintAddress(address["721"][contractNetwork]['mint_address'])
                setMintAbi(abi_mint_721)
                setMarketPlaceAbi(abi_marketPlace_721)
            } else if (nft['token_standard'] == TokenStandard[1]) { // 1155
                setMarketPlaceAddress(address["1155"][contractNetwork]['marketplace_address'])
                setMintAddress(address["1155"][contractNetwork]['mint_address'])
                if (nft.contract_address.toLowerCase() == CONTRACT_ADDRESS_SPECIAL.toLowerCase()) {
                    setMintAddress(CONTRACT_ADDRESS_SPECIAL)
                    setMintAbi(abi_mint_1155_special)
                } else {
                    setMintAddress(address["1155"][contractNetwork]['mint_address'])
                    setMintAbi(abi_mint_1155)
                }
                setMarketPlaceAbi(abi_marketplace_1155)
            }
        }

    }
    const updateLocalState = () => {
        var crntuser = auth?.user
        if (nft && Object.keys(nft).length) {
            // const priceListing = priceList && priceList[0] || {};
            if (crntuser) {
                // setPriceListingOwner(() => priceListing && priceListing['wallet_address'] && priceListing['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase())
                const owner = ownerListDetails?.owner_list.filter(e => e['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase()) || []

                setOwner(owner.length ? owner[0] : null)
                setCreator(nft?.user_wallet?.toLowerCase() === crntuser.wallet_address.toLowerCase() ? true : false)
                setEditable(nft.isEditable && nft?.user_wallet?.toLowerCase() === crntuser.wallet_address.toLowerCase() && (!nft.is_freezed_metadata || (!nft.is_partially_minted && nft.is_freezed_metadata)))


                if (nft.isOfferAccepted) {
                    if (crntuser._id === nft.accepted_by) {
                        setIsOfferUser(true)
                    }
                    setIsOfferclosed(true)
                }
            }
            if (nft.has_blur_effect) {
                setBlurEffect(nft.has_blur_effect)
            } else {
                setBlurEffect(false);
            }

            // console.log('details=>', nft);

            setPriceList((prevPrice) => {
                return prevPrice && prevPrice.map(price => {
                    const nftOwner = ownerListDetails && ownerListDetails.total_owner && ownerListDetails?.owner_list.find(owner => owner['wallet_address'].toLowerCase() === price['wallet_address'].toLowerCase())
                    price['is_minted'] = nftOwner && nftOwner['is_minted'] || false
                    return price;
                })
            })

            var collection = nft.collection_id ? nft.collection_id : false
            if (collection) {
                setCollection(collection)
            }

            if (nft.file_extension) {
                setFileType(nft.file_extension)
            }
            setTotalLikeCount(nft.total_likes || 0)
            setMystery(nft.isMystery);
            setShowSensitiveCoverImg(nft.explicit || false)
            setIsSpecialContract(nft.contract_address.toLowerCase() == CONTRACT_ADDRESS_SPECIAL.toLowerCase())
        }
    }
    const getNftOffers = async (nft) => {
        const column = {
            nft_id: nft._id,
            page: 1,
            limit: 5
        }
        let offers = await getOfferFilter(column)
        offers = offers?.offer?.latestOffres
        if (offers) {
            await setNftOfferces(offers)
        }
    }

    /*const GetWinnerOfBid = async (status = '') => {
        var bids = await getWinnerOfBid({ _id: nft._id })
        var crntuser = auth?.user
        if (bids && status) {
            setBidWinner(bids?.user_id === crntuser?.id ? true : false)
        }
    }*/

    const bidover = async (status) => {
        if (countTime) {
            setBidOver(status)
            // GetWinnerOfBid(status) 
        }
    }

    const checkBidEndPrice = async () => {
        // var bids = await getWinnerOfBid(nft._id)
    }

    const countDown = (date, type = '') => {

        setIsloading(true)
        var start = new Date()
        var end = !date ? new Date() : typeof (date) === 'object' ? date : new Date(date)
        var state = { startDate: start.getTime(), timeEnd: end.getTime() } // example
        const sGetWinnerOfBidtartDate = moment(state.startDate);
        const timeEnd = moment(state.timeEnd);
        const diff = timeEnd.diff(state.timeEndstartDate);
        const diffDuration = moment.duration(diff);
        var timer = {
            day: diffDuration.days(),
            hours: diffDuration.hours(),
            minutes: diffDuration.minutes(),
            seconds: diffDuration.seconds()
        }
        if (isSell && timer.day === 0 && timer.hours === 0 && timer.minutes === 0 && timer.seconds === 0) {
            setTimeEnd(true)
        }
        setIsloading(false)
        setCountTime(timer)
        return timer
    }
    const handleCancel = () => {
        setwalletVisibility(false)
        setOfferVisibility(false)
        setBidVisibility(false)
        setShowShareModal(false)
        setReportPopup(false)
        setOwnerListPopup(false)
        setShowTransferModal(false)
        setShowUnlockableContentModal(false)
        setBurnNftModal(false)
    }

    const nftBuyPopup = (priceListing, type = null) => {
        setPriceListingBuyNFT(priceListing);
        setwalletVisibility(true);

    }

    const mintNFT = async (buyRequest) => {
        let itemChainId = nft.network_chain_id;
        const web3 = new Web3(library.provider);
        let { user } = auth;
        let currentChainId = await web3.eth.getChainId();
        const priceListing = buyRequest.priceId_param;

        if (!itemChainId) {
            notification.open({
                message: "Error",
                description: "Invalid nft chain id",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return;
        }
        if (itemChainId !== currentChainId) {
            switchNetwork(String(itemChainId), library).then(() => NftBuy(buyRequest));
            return;
        }
        if (!currentChainId) {
            notification.open({
                message: "Error",
                description: "Invalid network selected",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return
        }

        const buyNowPrice = priceListing['price_unit'] === 'busd' ? 0 : Web3.utils.toWei(`${buyRequest.amount_param}`, "ether");
        const pid = priceListing['price_unit'] === 'busd' ? 0 : 99999;
        const cost = priceListing['price_unit'] === 'busd' ? Web3.utils.toWei(buyRequest['amount_param'], "ether") : 0;

        const metadataURL = METADATA_BASE_URL + '/' + nft["contract_address"] + '/' + nft["token_id"]
        const collection = nft.collection_id;
        var responsedata = null;

        try {
            // let web3Normal = new Web3(new Web3.providers.HttpProvider(CHAINS[itemChainId]['rpcUrls'][0]));
            // to do, need to pass token id
            const contract = new web3.eth.Contract(mintAbi, mintAddress);
            // mint(address to, uint256[] calldata tokenIds, string[] calldata tokenURIs, uint256[] calldata qty, uint256 _pId, uint256 cost) 
            if (isSpecialContract) {
                // buyBox(address to, uint256 id, uint256 qty, uint256 pId, uint256 cost)
                if (priceListing['price_unit'] == 'busd') {

                    const erc20Contract = new web3.eth.Contract(abi_busd_erc20, CONTRACT_ADDRESS_BUSD_ERC20);
                    var isApproved = await erc20Contract.methods
                        .approve(mintAddress, cost)
                        .send({ from: user.wallet_address });

                    if (isApproved) {
                        responsedata = await contract.methods
                            .buyBox(user.wallet_address, +nft["token_id"], buyRequest.quantity_param, pid, cost)
                            .send({ from: user.wallet_address });
                    } else {
                        notification.open({
                            message: "Error",
                            description: "Approval required for BUSD payment!",
                            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                        });
                    }
                } else {
                    responsedata = await contract.methods
                        .buyBox(user.wallet_address, +nft["token_id"], buyRequest.quantity_param, pid, cost)
                        .send({ from: user.wallet_address, value: buyNowPrice });
                }
            } else {
                if (priceListing['price_unit'] == 'busd') {
                    const erc20Contract = new web3.eth.Contract(abi_busd_erc20, CONTRACT_ADDRESS_BUSD_ERC20);
                    var isApproved = await erc20Contract.methods
                        .approve(mintAddress, cost)
                        .send({ from: user.wallet_address });

                    if (isApproved) {
                        responsedata = await contract.methods
                            .mint(user.wallet_address, [+nft["token_id"]], [metadataURL], [buyRequest.quantity_param], pid, cost)
                            .send({ from: user.wallet_address });
                    } else {
                        notification.open({
                            message: "Error",
                            description: "Approval required for BUSD payment!",
                            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                        });
                    }
                } else {
                    responsedata = await contract.methods
                        .mint(user.wallet_address, [+nft["token_id"]], [metadataURL], [buyRequest.quantity_param], pid, cost)
                        .send({ from: user.wallet_address, value: buyNowPrice });
                }
            }
            return responsedata;
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            return;
        }
    }

    const buyNFTMarketplace = async (buyRequest) => {
        let itemChainId = nft.network_chain_id;
        const web3 = new Web3(library.provider);
        let currentChainId = await web3.eth.getChainId();
        const nftOwnerAddress = buyRequest && buyRequest.priceId_param && buyRequest.priceId_param.wallet_address || null;

        if (!itemChainId) {
            notification.open({
                message: "Error",
                description: "Invalid nft chain id",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return;
        }
        if (itemChainId !== currentChainId) {
            switchNetwork(String(itemChainId), library).then(() => NftBuy(buyRequest));
            return;
        }
        if (!currentChainId) {
            notification.open({
                message: "Error",
                description: "Invalid network selected",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return
        }
        if (!nftOwnerAddress) {
            notification.open({
                message: "Error",
                description: "Owner address not found!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return;
        }

        const tokenId = nft.token_id;
        var crntuser = auth?.user;
        const userAdd = crntuser.wallet_address;

        const buyNowPrice = priceListing['price_unit'] === 'busd' ? 0 : Web3.utils.toWei(`${buyRequest.amount_param}`, "ether");
        const pid = priceListing['price_unit'] === 'busd' ? 0 : 99999;
        const cost = priceListing['price_unit'] === 'busd' ? Web3.utils.toWei(buyRequest['amount_param'], "ether") : 0;
        var CreatNewSale = null;

        try {
            const marketPlaceContract = new web3.eth.Contract(
                marketPlaceAbi,
                marketPlaceAddress
            );
            if (priceListing['price_unit'] == 'busd') {
                const erc20Contract = new web3.eth.Contract(abi_busd_erc20, CONTRACT_ADDRESS_BUSD_ERC20);
                var isApproved = await erc20Contract.methods
                    .approve(mintAddress, cost)
                    .send({ from: userAdd });

                if (isApproved) {
                    CreatNewSale = await marketPlaceContract.methods
                        .buyNFTOnMarket(nft.contract_address, +tokenId, nftOwnerAddress, buyNowPrice, +buyRequest.quantity_param, pid, cost)
                        .send({ from: userAdd, value: buyNowPrice })
                        .then((res) => {
                            return res;
                        })
                        .catch((error) => {
                            console.log("error", error);
                            setLoading(false);
                            return;
                        });
                } else {
                    notification.open({
                        message: "Error",
                        description: "Approval required for BUSD payment!",
                        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                    });
                }
            } else {
                // buyNFTOnMarket(address _nftContractAddress,uint256 _tokenId,address _from,uint256 _newPrice,uint256 _qty,uint256 _pId, uint256 cost)
                CreatNewSale = await marketPlaceContract.methods
                    .buyNFTOnMarket(nft.contract_address, +tokenId, nftOwnerAddress, buyNowPrice, +buyRequest.quantity_param, pid, cost)
                    .send({ from: userAdd, value: buyNowPrice })
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        console.log("error", error);
                        setLoading(false);
                        return;
                    });
            }
            return CreatNewSale;
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            return;
        }
    }

    const NftBuy = async (buyRequest) => {
        var crntuser = auth?.user;
        var buyerprofile = crntuser;
        var sellerprofile = await getUser(nft.user_id._id);
        const userAdd = crntuser.wallet_address;

        setLoading(true);
        try {
            var CreatNewSale = null;
            if (buyRequest.priceId_param['is_minted']) {
                CreatNewSale = await buyNFTMarketplace(buyRequest);
            } else {
                CreatNewSale = await mintNFT(buyRequest);
            }
            if (!CreatNewSale) {
                setLoading(false);
                notification.open({
                    message: "Error",
                    description:
                        "Something went wrong... Make Sure Are Connected With Mainnet!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
                return;
            }
        } catch (error) {
            setLoading(false);
            console.log("error55", error);
            return;
        }

        // var data = {
        //   owner_id: crntuser._id,
        //   start_date: undefined,
        //   end_date: undefined,
        //   type: undefined,
        //   collection_id: undefined,
        //   collection_name: undefined,
        //   ready_for_sell: false,
        //   isEditable: false,
        // };

        const nftAuction = CreatNewSale;
        let data = {
            quantity: buyRequest.quantity_param,
            wallet_address: userAdd,
            ethTransactionHash: nftAuction.transactionHash,
            amount: buyRequest.amount_param,
            nftPriceId: buyRequest.priceId_param['_id']
        }

        // var nftResponse = await update(data, buyRequest);
        let nftResponse = await buyListing(data, urlNetwork ,nft['contract_address'], nft['token_id']);
        if (nftResponse.data.success) {
            let savedNftData = nftResponse?.data?.data?.receiverNft
            const contractNetwork = nft["network"] + "_" + nft["network_chain_id"];
            var dataTransaction = {
                saller_id: buyRequest.priceId_param['user_id'],
                nft_id: savedNftData._id,
                buyer_id: crntuser._id,
                from_wallet_address: sellerprofile?.wallet_address,
                to_wallet_address: crntuser?.wallet_address,
                event: "Sold",
                seller_username: sellerprofile?.username,
                buyer_username: buyerprofile.username,
                price: nftResponse.start_price,
                url: NetworkURLs[contractNetwork]["scanUrl"] + "/tx/",
                tnxHasId: CreatNewSale.transactionHash,
                date: undefined,
            };
            var transtion = await transaction(dataTransaction);
            notification.open({
                message: "NFT",
                description: "NFT Successfully Bought...!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            await setwalletVisibility(false);
            initAPIs();
            setLoading(false);
        } else {
            setLoading(false);
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
        }
    };

    const NftTransfer = async ({ to_wallet_address, quantity: quantity = 1 }) => {

        let itemChainId = nft.network_chain_id
        if (!itemChainId) {
            notification.open({
                message: "Error",
                description: "Invalid nft chain id",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return
        }
        const web3 = new Web3(library.provider)
        let currentChainId = await web3.eth.getChainId();

        if (itemChainId !== currentChainId) {
            switchNetwork(String(itemChainId), library).then(() =>
                NftTransfer({ to_wallet_address, quantity })
            );
            return
        }

        setLoading(true)

        const crntuser = auth?.user

        try {
            const mintContract = new web3.eth.Contract(mintAbi, mintAddress);
            const params = {
                from: String(account), //current user address
                to: mintAddress, //contract address
            };
            if (nft['token_standard'] === TokenStandard[1]) { // 1155
                if (isSpecialContract) {
                    params['data'] = mintContract.methods
                        .safeTransferFrom(
                            account,
                            to_wallet_address,
                            Number(nft?.token_id),
                            Number(quantity),
                            []
                        )
                        .encodeABI();
                } else {
                    params['data'] = mintContract.methods
                        .safeTransferFrom(
                            account,
                            to_wallet_address,
                            Number(nft?.token_id),
                            Number(quantity),
                            []
                        )
                        .encodeABI();
                }

            } else { // 721
                params['data'] = mintContract.methods
                    .safeTransferFrom(account, to_wallet_address, Number(nft?.token_id))
                    .encodeABI();
            }

            web3.eth
                .sendTransaction(params)
                .then(async (res) => {
                    var data = {
                        to_wallet_address,
                        quantity: +quantity
                    }

                    const nftResponse = await transferNFT(data, urlNetwork, nft['contract_address'], nft['token_id'])

                    const contractNetwork = nft['network'] + '_' + nft['network_chain_id']
                    var dataTransaction = {
                        from_wallet_address: crntuser['wallet_address'] || null,
                        to_wallet_address,
                        quantity,
                        nft_id: nft['_id'],
                        event: 'Transfer',
                        url: NetworkURLs[contractNetwork]['scanUrl'] + "/tx/",
                        tnxHasId: res.transactionHash,
                        date: new Date()
                    }

                    if (nftResponse.success) {
                        var transtion = await transaction(dataTransaction)
                        await setShowTransferModal(false)
                        initAPIs()
                        notification.open({
                            message: "NFT",
                            description: "NFT Successfully Transferred...!",
                            icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                        });
                        // window.location.reload()
                        return;
                    } else {
                        notification.open({
                            message: "Error",
                            description: nftResponse['message'] || "Something went wrong...!",
                            icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                        });
                        return;
                    }
                }).catch((error) => {
                    setLoading(false)
                    notification.open({
                        message: "Error",
                        description: "Something went wrong... Make Sure Are Connected With Mainnet!",
                        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                    });
                    return
                });
        } catch (error) {
            setLoading(false)
            return
        }
    }

    // Burn minted nft.
    const NftBurn = async (data) => {
        let itemChainId = nft.network_chain_id
        if (!itemChainId) {
            notification.open({
                message: "Error",
                description: "Invalid nft chain id",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return
        }
        const web3 = new Web3(library.provider)
        let currentChainId = await web3.eth.getChainId();

        if (itemChainId !== currentChainId) {
            switchNetwork(String(itemChainId), library).then(() =>
                NftBurn(data)
            );
            return
        }
        setLoading(true)

        try {
            const params = {
                from: String(account), //current user address
                to: mintAddress, //contract address
            };
            const mintContract = new web3.eth.Contract(mintAbi, mintAddress);
            // burn(address account, uint256 _tokenId, uint256 amount) 
            params['data'] = mintContract.methods
                    .burn(data.to_wallet_address, Number(nft?.token_id), +data.quantity)
                    .encodeABI();
                    // console.log('mintContract', mintContract, params);
                    
            web3.eth
            .sendTransaction(params)
            .then(async (res) => {
                // console.log('transaction res', res);
                let burnParams = {
                    quantity: +data.quantity,
                    wallet_address: data.to_wallet_address 
                }
                const burnResponse = await burnSelectedNft(burnParams, nft['chain_name'], nft['contract_address'], nft['token_id']);
                getOwnerList();
                fetchNftDetails();
                if (burnResponse.success) {
                    setBurnNftModal(false)
                    setLoading(false)
                    notification.open({
                        message: "NFT",
                        description: "NFT Successfully Burned...!",
                        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                    });
                    return;
                } else {
                    setLoading(false)
                    notification.open({
                        message: "Error",
                        description: burnResponse['message'] || "Something went wrong...!",
                        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                    });
                    return;
                }
            }).catch((error) => {
                setLoading(false)
                notification.open({
                    message: "Error",
                    description: "Something went wrong... Make Sure Are Connected With Mainnet!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
                return
            });

        } catch (error) {
            console.log('Burn error', error);
            setLoading(false);
            return;
        }

    }

    const saveOffer = async () => {
        var user = auth?.user
        var start = ''
        var end = ''
        if (expiration === 0) {
            var { start, end } = await handelDuration(1)
        }
        else {
            var { end, start } = await handelDuration(expiration)
        }
        const column = {
            user_id: user._id,
            nft_id: nft._id
        }
        var old = await getOfferFilter(column)
        old = old.offer.latestOffres

        var data = {
            offertprice,
            price: offertprice,
            offer_start_expiration: start,
            offer_end_expiration: end,
            user_id: user._id,
            nft_id: nft._id,
            nft_img_url: nft.item_image,
            nft_name: nft.item_name,
            offer_for_user_id: nft.owner_id,
            bider_name: user ? user._id : user.username
        }

        var res

        if (old.length === 0) {
            res = await create(data)
            res = res.offer

            var nftOfferceData = await getOfferFilter({ nft_id: nft._id })
            nftOfferceData = nftOfferceData?.offer?.latestOffres
            await setNftOfferces(nftOfferceData)
            if (res) {
                notification.open({
                    message: "NFT",
                    description: "Offer Successfully Send To The Seller...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                setOfferVisibility(false)

            } else {
                notification.open({
                    message: "Error",
                    description: "Something went wrong...!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        }
        else {
            delete data.user_id
            delete data.nft_id
            res = await offerUpdate(data, old._id)
            var nftOfferceData = await getOfferFilter({ nft_id: nft._id })
            nftOfferceData = nftOfferceData.offer

            await setNftOfferces(nftOfferceData)
            if (res) {
                notification.open({
                    message: "NFT",
                    description: "Offer Successfully Updated...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                setOfferVisibility(false)

            } else {
                notification.open({
                    message: "Error",
                    description: "Something went wrong...!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        }
    }

    const nftBidPopup = () => {
        setBidVisibility(true)
    }

    const likeCountUpdate = (value) => {
        setTotalLikeCount(totalLikeCount + value)
    }

    /*const checkBidUserIsCorrect = async () => {
        var bid = await getWinnerOfBid()
        var crntuser = auth?.user
        if (bid) {
            if (bid.user_id === crntuser._id) {
                return true
            }
            else {
                return false
            }
        }
        return false
    }*/

    /*const placeBid = async () => {
        // setBidError('')

        if (isBidOver) {
            setBidOver(isBidOver)
            notification.open({
                message: "Error",
                description: "Bid over",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            // GetWinnerOfBid()
            // alert('Bid Closed!')
            return
        }
        if ( !priceListing || !Object.keys(priceListing).length ) {
            notification.open({
                message: "Error",
                description: "Invalid Price!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            return
        }
        if (bidPrice === '') {
            // setBidError('Please Enter Bid Price!')
            return;
        }
        if (bidPrice < crntprice) {
            // setBidError(`You can't offer less than current price!`)
            return
        }
        var crntuser = auth?.user
        setLoading(true);

        var data = {
            price: bidPrice,
            nft_id: nft._id,
            price_id: priceListing._id
        }

        var res = await makeBid(data)
        if (res) {
            setLoading(false);
            // setBidPrice("");
            notification.open({
                message: "NFT",
                description: "Your Bid Successfully Placed...!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            // var isbidover = nft.end_price > 0 ? parseFloat(nft.end_price) <= parseFloat(bidPrice) ? true : false : false;

            // await setCrntPrice(res.bid_price ? res.bid_price : res.start_price)
            initAPIs();
            setBidVisibility(false)
            // setBidOver(isbidover)
        } else {
            setLoading(false);
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
        }

    }*/

    const cancelSell = async (listing) => {
        const user = auth?.user;
        const userAdd = user.wallet_address
        var CreatNewSalem = null

        try {
            setLoading(true)
            if (isOwner && isOwner['is_minted']) {
                const web3 = new Web3(library.provider)
                const marketPlaceContract = new web3.eth.Contract(marketPlaceAbi, marketPlaceAddress);
                const pid = priceListing['price_unit'] === 'busd' ? 0 : 99999;

                // cancleNFTOnMarket(address _nftContractAddress,uint256 _tokenId,uint256 _qty,uint256 _pId)
                CreatNewSalem = await marketPlaceContract.methods.cancleNFTOnMarket(nft.contract_address, +nft.token_id, listing.supply_to_sale, pid).send({ from: userAdd });
                const contractNetwork = nft['network'] + '_' + nft['network_chain_id']
                var dataTransaction = {
                    nft_id: nft._id,
                    event: 'Cancelled',
                    seller_username: user ? user.username : '',
                    url: NetworkURLs[contractNetwork]['scanUrl'] + "/tx/",
                    tnxHasId: CreatNewSalem.transactionHash,
                    date: new Date()
                }
                var transtion = await transaction(dataTransaction)
                await fetchNftDetails();
            }

            var nftResponse = await cancelPriceList(listing._id)
            if (nftResponse.success) {
                setLoading(false)
                notification.open({
                    message: "NFT",
                    description: "NFT Sell Successfully Cancelled...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                initAPIs();
            } else {
                notification.open({
                    message: "Error",
                    description: "Something went wrong...!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        } catch (error) {
            console.log('error', error)
            setLoading(false)
            return
        }
    }

    const cancelExpiredList = async () => {
        const user = auth?.user;
        const userAdd = user.wallet_address

        const confirmationResp = await showConfirm('Are you sure to cancel the expired list?', 'When clicked the OK button, expired list will be cancelled.');

        try {
            if (isOwner && isOwner['is_minted'] && confirmationResp['success']) {
                setLoading(true)
                const web3 = new Web3(library.provider)
                const marketPlaceContract = new web3.eth.Contract(marketPlaceAbi, marketPlaceAddress);
                const pid = priceListing['price_unit'] === 'busd' ? 0 : 99999;

                // cancleNFTOnMarket(address _nftContractAddress,uint256 _tokenId,uint256 _qty,uint256 _pId)
                var CreatNewSalem = await marketPlaceContract.methods.cancleNFTOnMarket(nft.contract_address, +nft.token_id, +nftMarketItemQuantity['quantity'], +nftMarketItemQuantity['pid']).send({ from: userAdd });

                const contractNetwork = nft['network'] + '_' + nft['network_chain_id']
                var dataTransaction = {
                    nft_id: nft._id,
                    event: 'Cancelled',
                    seller_username: user ? user.username : '',
                    url: NetworkURLs[contractNetwork]['scanUrl'] + "/tx/",
                    tnxHasId: CreatNewSalem.transactionHash,
                    date: new Date()
                }
                var transtion = await transaction(dataTransaction)
                if (transtion.success) {
                    setLoading(false)
                    setNftMarketItemQuantity({attempt: 1, quantity: 0, quantity: 0});
                    notification.open({
                        message: "NFT",
                        description: "NFT Sell Successfully Cancelled...!",
                        icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                    });
                    initAPIs();
                } else {
                    setLoading(true)
                    notification.open({
                        message: "Error",
                        description: "Something went wrong...!",
                        icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                    });
                }
            }
        } catch (error) {
            console.log('error', error)
            setLoading(false)
            return
        }
    }

    const cancelBidRequest = async (row) => {
        try {
            setLoading(true)
            var nftResponse = await cancelBid(row._id)
            if (nftResponse.success) {
                setLoading(false)
                notification.open({
                    message: "Bid",
                    description: "Bid Successfully Cancelled...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                initAPIs();
            } else {
                setLoading(false)
                notification.open({
                    message: "Error",
                    description: "Something went wrong...!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        } catch (error) {
            console.log('error', error)
            setLoading(false)
            return
        }
    }

    const acceptBidRequest = async (row) => {
        try {
            setLoading(true)
            var nftResponse = await acceptBid(row._id)
            if (nftResponse.success) {
                setLoading(false)
                notification.open({
                    message: "Bid",
                    description: "Bid Successfully Accepted...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                initAPIs();
            } else {
                setLoading(false)
                notification.open({
                    message: "Error",
                    description: nftResponse.message || "Something went wrong...!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
            }
        } catch (error) {
            console.log('error', error)
            setLoading(false)
            return
        }
    }



    const getNftUnlockableContent = async (event) => {
        event.preventDefault();
        setShowUnlockableContentModal(true);
        unlockContentModalRef && unlockContentModalRef?.current?.showContentHandler && unlockContentModalRef.current.showContentHandler(urlNetwork, contract_address, token_id);
        /* setNftUnlockableContent({ loading: true, data: null, success: false })
         const data = await fetchNftUnlockableContent({ urlNetwork, contract_address, token_id })
         if (data) {
             setNftUnlockableContent({ loading: false, data: data.content, success: true })
         } else {
             setNftUnlockableContent({ loading: false, data: null, success: false })
         }*/
    }
    const getEstimatedAmount = async (params) => {

        let response;
        try {
            let itemChainId = nft.network_chain_id;
            if (!itemChainId) {
                notification.open({
                    message: "Error",
                    description: "Invalid nft chain id",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
                return;
            }
            const web3 = new Web3(library.provider);
            let currentChainId = await web3.eth.getChainId();

            if (itemChainId !== currentChainId) {
                switchNetwork(String(itemChainId), library).then(() => getEstimatedAmount(params));
                return;
            }

            const userAdd = auth?.user?.wallet_address;
            const priceListing = params.priceId_param
            const metadataURL = METADATA_BASE_URL + '/' + nft["contract_address"] + '/' + nft["token_id"]
            const collection = nft.collection_id;
            const buyNowPrice = priceListing['price_unit'] === 'busd' ? 0 : Web3.utils.toWei(`${params.amount}`, "ether");
            const pid = priceListing['price_unit'] === 'busd' ? 0 : 99999;
            const cost = priceListing['price_unit'] === 'busd' ? Web3.utils.toWei(params.amount, "ether") : 0;

            if (params.priceId_param['is_minted']) {
                const marketPlaceContract = new web3.eth.Contract(
                    marketPlaceAbi,
                    marketPlaceAddress
                );

                // buyNFTOnMarket(address _nftContractAddress,uint256 _tokenId,address _from,uint256 _newPrice,uint256 _qty,uint256 _pId, uint256 cost)
                response = await marketPlaceContract.methods
                    .buyNFTOnMarket(nft.contract_address, +nft.token_id, params.owner_address, buyNowPrice, +params.qty, pid, cost)
                    .estimateGas()
                    .then((gasEstimate) => {
                        const increasedValued = getPercentageAmount(gasEstimate, 20, true);
                        return { success: true, message: 'success', estimatedGasFee: increasedValued, chainId: currentChainId }
                    }).catch((error) => {
                        return { success: false, message: error?.message }
                    });
                return response
            } else {
                try {
                    const contract = new web3.eth.Contract(mintAbi, mintAddress);

                    // mint(address to, uint256[] calldata tokenIds, string[] calldata tokenURIs, uint256[] calldata qty, uint256 _pId, uint256 cost) 
                    var responsedata = await contract.methods
                        .mint(userAdd, [+nft.token_id], [metadataURL], [+params.qty], pid, cost)
                        .estimateGas()
                        .then((gasEstimate) => {
                            const increasedValued = getPercentageAmount(gasEstimate, 20, true);
                            return { success: true, message: 'success', estimatedGasFee: increasedValued, chainId: currentChainId }

                        }).catch((error) => {
                            return { success: false, message: error?.message }
                        });
                    return responsedata;
                } catch (error) {
                    console.log("error", error);
                    setLoading(false);
                    return;
                }
            }
        } catch (error) {
            response = { success: false, message: error?.message }
            return response
        }
    }

    const deleteNonmintedItem = async () => {
        // console.log('cal delete api', nft);
        const confirmationResp = await showConfirm('Do you want to delete this item?', 'When clicked the OK button, this item will be deleted.');
        if ( confirmationResp['success'] ) {
            let deleteParams = {
                network: nft.chain_name,
                contract_address: nft.contract_address,
                token_id: nft.token_id
            }
    
            let deleteResponse = await deleteNFT(deleteParams)
            // console.log('del=>', deleteResponse);
            if (deleteResponse) {
                notification.open({
                    message: "NFT",
                    description: "NFT Deleted Successfully...!",
                    icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                });
                history.push(`/profile/${nft?.user_id?.username}`)
            }
        }
    }

    const showConfirm = async (title, content) => {
        return new Promise((resolve, reject) => {
            confirm({
                title,
                content,
                async onOk() {
                    try {
                        return resolve({success: true})
                    } catch (e) {
                        console.log('Oops errors!');
                        return resolve({success: false})
                    }
                },
                onCancel() { 
                    return resolve({success: false})
                },
            });
        })
        /*confirm({
            title: 'Do you want to delete this item?',
            content:
                'When clicked the OK button, this item will be deleted.',
            async onOk() {
                try {
                    deleteNonmintedItem();
                } catch (e) {
                    return console.log('Oops errors!');
                }
            },
            onCancel() { },
        });*/
    }

    const sellCancelConfirm = async (listing) => {
        confirm({
            title: 'Do you want to cancel this listing?',
            content:
                'Please click on "OK" to cancel the listing or "Cancel" to keep the listing',
            async onOk() {
                try {
                    cancelSell(listing);
                } catch (e) {
                    return console.log('Oops errors!');
                }
            },
            onCancel() { },
        });
    }

    const nftMarketItems = async (userWallet) => {
        try {
            const web3 = new Web3(library.provider);
      
            let itemChainId = nft && nft.network_chain_id ? nft.network_chain_id : null;
            if (!itemChainId) {
              notification.open({
                message: "Error",
                description: "Invalid nft chain id",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
              });
              return;
            }
            let currentChainId = await web3.eth.getChainId();
            if (itemChainId !== currentChainId) {
              switchNetwork(String(itemChainId), library)
              return;
            }

            const contractInit = new web3.eth.Contract(marketPlaceAbi, marketPlaceAddress);
            var marketItemResp = await contractInit.methods.nftMarketItems(nft['contract_address'], nft['token_id'], userWallet).call()

            var crntuser = auth?.user
            const userPriceList = priceList && priceList.filter(e => e['wallet_address'].toLowerCase() == crntuser?.wallet_address.toLowerCase() && e['status'] == 'active')

            if ( priceList && !priceList.length && (!userPriceList || (userPriceList && !userPriceList.length)) && marketItemResp && marketItemResp['qty'] && +marketItemResp['qty'] > 0 ) {
                setNftMarketItemQuantity({quantity: +marketItemResp['qty'], pid: marketItemResp['pId'], attempt: 1})
            } else {
                setNftMarketItemQuantity({attempt: 1, quantity: 0, quantity: 0})
            }
        } catch (err) {
            console.log('1. err: ', err)
            notification.open({
              message: "NFT",
              description: "Transaction aborted!",
              icon: <SmileOutlined style={{ color: "#f4364c" }} />,
            });
            return;
        }
    }


    if ((!loading && Object.keys(nft).length == 0)) {
        return <PageNotFound text={'NFT Not Found'} />
    }
    if ((!auth?.user && isfetching) || nftFetching) {
        return (
            <NFTDetailPageCreate />
        );
    }

    return (
        <div className="games__wrap mt-10 NftDetailPageComponent">
            <div className="container">
                <div className="row">
                    <div className="col-12 form-transp-bg pt-3 pb-3">
                        {/* <h3 className="oracleTextClass" style={{ }}>NFT Details</h3>
                        <h3 className="nft-detail-hr"></h3> */}
                        {
                            nft?.is_spam_reported ?
                                <div className="alert alert-danger" role="alert">
                                    <i className="fas fa-exclamation-triangle"></i> &nbsp;
                                    Reported for suspicious activity!
                                </div> : ''
                        }

                        <div className="row">
                            <div className="col-6 p-0 pr-5">
                                <div className={"nft-detail-img" + (blurEffect ? " report-image" : '') + ((showSensitiveCoverImg && nft?.explicit) ? " sensitive-img-cover" : '')} onClick={() => setShowSensitiveCoverImg(!showSensitiveCoverImg)}>
                                    {/* {nft && nft.collection_id.banner_image !== null && nft.collection_id.banner_image !== undefined || nft && nft.collection_id.banner_resized_image !== null && nft.collection_id.banner_resized_image !== undefined ? (
                                        <span className="cover-image"><img src={nft && nft.collection_id.banner_resized_image ? nft.collection_id.banner_resized_image : nft.collection_id.banner_image} /></span>
                                    ) : ''} */}
                                    {
                                        nft && nft.collection_id ?
                                            <span className="cover-image">
                                                <img src={nft?.collection_id?.banner_resized_image || nft?.collection_id?.banner_image} />
                                            </span> : ''
                                    }

                                    {/* Make the above Image Dynamic */}
                                    {/* <div className="fav-icon active"><img src={favImage}></img></div> */}
                                    {nft?._id  /*&& nft.owner_id !== auth?.user?._id*/ ?

                                        <LikeDislikeNft nftId={nft?._id} successHandler={(value) => likeCountUpdate(value)} /> : null
                                    }

                                    {/* {console.log('nftdetails=>', nft)} */}

                                    {nft?.file_extension === 'video' ?
                                        <video playsInline muted controls autoPlay={true} loop="" preload="auto" src={nft && nft.item_resized_image ? nft.item_resized_image : nft.item_image} style={{ width: "100%", height: 'calc(100% - 50px' }}></video>
                                        : ''}
                                    {nft?.file_extension === 'image' ?
                                        <img src={nft && nft.item_resized_image ? nft.item_resized_image : nft.item_image} />   //'https://via.placeholder.com/80x121.png?text=Use'
                                        : ''}
                                    {!nft.file_extension ?
                                        <img src={nft && nft.item_resized_image ? nft.item_resized_image : nft.item_image} />   //'https://via.placeholder.com/80x121.png?text=Use'
                                        : ''}
                                    {nft?.file_extension === 'audio' ?
                                        <audio controls >
                                            <source src={nft && nft.item_resized_image ? nft.item_resized_image : nft.item_image} />
                                        </audio>
                                        : ''}
                                </div>

                                {/* <div className="nft-detail-informations flex-column align-center-start justify-content-start">
                                    <h3>{nft ? nft.item_name : ''}</h3>
                                    <h5 className="text-left">{nft ? nft.collection_id?.collection_name : ''}</h5>
                                    <p className="mt-4">{nft ? nft.description : ''}</p>
                                </div> */}
                                {/* <div className="details-info nft-properties d-flex justify-content-between align-items-center my-3">
                                    <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                        <img src={transferImage} />
                                        <h4>Properties</h4>
                                    </div>
                                    <img className="mr-3" src={SelectArrow} />
                                </div>hoursMinSecs
                                <div className="details-info nft-properties d-flex justify-content-between align-items-center mb-3">
                                    <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                        <img src={transferImage} />
                                        <h4>Details</h4>
                                    </div>
                                    <img className="mr-3" src={SelectArrow} />
                                </div> */}

                            </div>
                            <div className="col-6 p-0">
                                <div className='nft-details-top-heading d-flex justify-content-between'>
                                    <div className='nft-details-item-name'>

                                    </div>
                                    <div className="nft-quick-info d-flex justify-content-around align-items-center nft-quick-info-custom">
                                        {/* <div className="flex-column height-100">
                                            <h4>Refresh</h4>
                                            <span className="reload-icon" title="Refresh" onClick={(() => window.location.reload())}><i className="fa fa-undo"></i></span>
                                        </div> */}
                                        {/* <div className="flex-column transfer-btn-link" onClick={() => setShowTransferModal(true)}>
                                                    <h4>Transfer</h4>
                                                     <img src={transferImage} />
                                                    <span className="reload-icon new-btn-name" title="Transfar"><i className="fa fa-map-signs"></i> <em>Transfer</em></span>
                                                </div> */}

                                        {

                                            isOwner && isOwner['is_minted'] ?
                                                <div className="flex-column transfer-btn-link" onClick={() => setShowTransferModal(true)}>
                                                    <h4>Transfer</h4>
                                                    {/* <img src={transferImage} /> */}
                                                    <span className="reload-icon new-btn-name" title="Transfar"><i className="fa fa-map-signs"></i> <em>Transfer</em></span>
                                                </div> : ''
                                        }
                                        <div onClick={() => setShowShareModal(true)} className="flex-column">
                                            <h4>Share</h4>
                                            {/* <img src={GroupshareSocial} /> */}
                                            <span className="reload-icon new-btn-name" title="Share"><i className="fa fa-share-alt"></i> <em>Share</em></span>
                                        </div>
                                        {/*islogin ?
                                            <div onClick={() => setReportPopup(true)} className="flex-column">
                                                <h4>Report</h4>
                                                <span className="reload-icon" title="Report NFT"><i className="fa fa-flag"></i></span>
                                            </div>
                                            : null*/}
                                    </div>
                                </div>


                                <div className="nft-detail-informations-right-col">
                                    <div className="nft-detail-informations flex-column align-center-start justify-content-start">
                                        <h3>{nft?.item_name || ''}</h3>

                                        <h5 className="text-left mr-2">{nft ? <NavLink to={nft?.collection_id ? "/collection/" + nft?.collection_id?.slug : ''}>Collection: {nft.collection_id?.collection_name}</NavLink> : ''}</h5>
                                        {/* <p className="mt-4">{nft?.description || ''}</p> */}
                                        {/* <h5 className='text-left'>{nft && nft.user_id ? <NavLink to={`/profile/${nft?.user_id?.username}`} >{nft?.user_id?.username}</NavLink> : ''} </h5> */}
                                        <span className='text-left by-author'>by {nft && nft.user_id ? <NavLink to={`/profile/${nft?.user_id?.username}`} >{nft?.user_id?.username}</NavLink> : ''} </span>
                                        <ReadMoreContent content={nft ? nft?.description : ''} maxCharac={100} className={"mt-4"} />
                                    </div>
                                </div>
                                <div className="nft-detail-informations flex-column align-center-start justify-content-start">
                                    <div className="nft-detail-calltoac">
                                        {
                                            ownerListDetails ?
                                                <div className="calltoac-border mb-3 details-stats-pan">
                                                    {
                                                        ownerListDetails?.total_owner > 1 ?
                                                            <>
                                                                <div className="details-stats-block">
                                                                    <span className="owner-span"><i className="fa fa-users"></i> {ownerListDetails?.total_owner}</span> <a href="#" onClick={event => { event.preventDefault(); setOwnerListPopup(true) }}>owners</a>
                                                                </div>
                                                                {
                                                                    nft?.supply > 1 ?
                                                                        <div className="details-stats-block">
                                                                            <span><i className="fa fa-th-large" ></i> Total <em>{nft?.supply}</em></span>
                                                                        </div> : ''
                                                                }
                                                                {
                                                                    isOwner ?
                                                                        <div className="details-stats-block">
                                                                            <span><i className="fa fa-eye"></i> You own <em>{isOwner?.quantity}</em></span>
                                                                        </div> : ''
                                                                }
                                                                <div className="details-stats-block">
                                                                    <span><i className="fa fa-eye"></i> Views <em>{nft?.view_count}</em></span>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="owned-by by-author">
                                                                    <span><i className="fa fa-child"></i> Owned by</span> {isOwner ? <Link target={"_blank"} to={"/profile/" + auth?.user?.username}>you</Link> :
                                                                        <Link target={"_blank"} to={"/profile/" + ownerListDetails?.owner_list[0]?.user}><WalletAddress wallet={ownerListDetails && ownerListDetails.owner_list && ownerListDetails.owner_list.length ? ownerListDetails.owner_list[0].wallet_address : ''} /></Link>}
                                                                </div>
                                                                {
                                                                    nft?.supply > 1 ?
                                                                        <div className="details-stats-block">
                                                                            <span><i className="fa fa-th-large" ></i> Total <em>{nft?.supply}</em></span>
                                                                        </div> : ''
                                                                }
                                                                <div className="owned-by-view">
                                                                    <span><i className="fa fa-eye"></i> Views <em>{nft?.view_count}</em></span>
                                                                </div>
                                                            </>
                                                    }
                                                    <div className="details-stats-block">
                                                        <span><i className="fa fa-thumbs-up"></i> Likes <em>{totalLikeCount}</em></span>
                                                    </div>
                                                </div> : ''
                                        }
                                        {
                                            nft?.unlockable ?
                                                <div className="nft-sale-ends calltoac-border mb-3">
                                                    <div className='nft-sale-end-info'>
                                                        <b><i className="fa fa-lock" aria-hidden="true"></i> Includes</b>
                                                        &nbsp;&nbsp;
                                                        <a href='#' onClick={((event) => islogin ? getNftUnlockableContent(event) : '')}>Unlockable Content</a>
                                                    </div>
                                                </div> : ''
                                        }
                                        {isSell ?
                                            <>
                                                {
                                                    priceListing && priceListing?.method !== MethodName[0] ?
                                                        <div className="nft-sale-ends calltoac-border mb-3">
                                                            <img src={saleEnd} />
                                                            {countTime ? <CountDownTimer bidover={bidover} isBidOver={!isSell} hoursMinSecs={isSell ? countTime : { day: 0, hours: 0, minutes: 0, seconds: 0 }} /> : 'Bid Over'}
                                                        </div>
                                                        : priceListing && priceListing?.method == MethodName[0] ?
                                                            <>
                                                                {
                                                                    moment(priceListing?.end_date).diff(moment(), 'days') > 1 ?
                                                                        <div className="nft-sale-ends calltoac-border mb-3">
                                                                            <img src={saleEnd} />
                                                                            <div className='nft-sale-end-info'>
                                                                                <h5>Sale ends</h5>
                                                                                <b>{moment(priceListing?.end_date).format(`MMM DD, YYYY hh:mma Z`)}</b>
                                                                            </div>
                                                                        </div> :
                                                                        <div className="nft-sale-ends calltoac-border mb-3">
                                                                            <img src={saleEnd} />
                                                                            {countTime ? <CountDownTimer bidover={bidover} isBidOver={!isSell} hoursMinSecs={isSell ? countTime : { day: 0, hours: 0, minutes: 0, seconds: 0 }} /> : 'Sale End'}
                                                                        </div>
                                                                }
                                                            </> : ''
                                                }
                                                <div className="nft-current-price calltoac-border mb-3">
                                                    {
                                                        priceListing?.price_unit === 'busd' ? '' : <img src={networkPriceLogo} />
                                                    }

                                                    <div className="nft-current-price-info">
                                                        <h5>Current price</h5>
                                                        <ItemPrice
                                                            priceList={priceListing}
                                                            render={(price, priceUSD) => (
                                                                <>
                                                                    <b>
                                                                        {
                                                                            priceListing?.price_unit === 'busd' ?
                                                                                <>{parseFloat(parseFloat(price).toFixed(4))} BUSD</> :
                                                                                <>
                                                                                    {price ? parseFloat(parseFloat(price).toFixed(8)) + ` (${'$' + priceUSD.toFixed(2)} USD)` : ''}
                                                                                </>
                                                                        }
                                                                    </b>
                                                                </>
                                                            )}
                                                            network={network} />
                                                        {/* <b>{nft ? crntprice ? crntprice + ` (${'$' + (crntprice * network?.exchange_rate_usd).toFixed(2)} USD)` : '' : ''} </b> */}
                                                    </div>
                                                </div>
                                            </> : ''
                                        }
                                        {isOfferUser ?
                                            <a href={islogin && isPriceListingOwner ? "#headingPricing" : "javascript:void(0)"} className={"nft-buynow-btn" + (isPriceListingOwner ? " disable-button" : "")} role="button" title={isPriceListingOwner ? 'You own this item' : ''}>
                                                <img src={walletButton} />
                                                <h5 onClick={(() => islogin && !isPriceListingOwner ? nftBuyPopup(priceListing) : '')}>{islogin ? 'BUY NOW ' : <span data-toggle="modal" data-target="#exampleModalCenter">Connect Your Wallet First!</span>}</h5>
                                            </a>
                                            : ''}
                                        {!timeEnd && !isOfferClosed ?
                                            <>
                                                {bidWinner ?
                                                    <a href={islogin && isPriceListingOwner ? "#headingPricing" : "javascript:void(0)"} className={"nft-buynow-btn" + (isPriceListingOwner ? " disable-button" : "")} title={isPriceListingOwner ? 'You own this item' : ''}>
                                                        <img src={walletButton} />
                                                        <h5 onClick={(() => islogin && !isPriceListingOwner ? nftBuyPopup(priceListing) : '')}>{islogin ? 'BUY NOW' : <span data-toggle="modal" data-target="#exampleModalCenter">Connect Your Wallet First!</span>}</h5>
                                                    </a> : ''}
                                                {
                                                    isSell && priceListing && Object.keys(priceListing).length ?
                                                        <>
                                                            {
                                                                priceListing?.method == MethodName[1] ?
                                                                    <>
                                                                        <div className={"nft-buynow-btn" + (isPriceListingOwner ? " disable-button" : "")} title={isPriceListingOwner ? 'You own this item' : ''}>
                                                                            <img src={walletButton} />
                                                                            <h5 onClick={(() => islogin && !isPriceListingOwner ? nftBidPopup() : '')}>{MethodName[1]} {priceListing.method} Place Bid</h5>
                                                                        </div>
                                                                    </>
                                                                    : <>
                                                                        <a href={islogin && isPriceListingOwner ? "#headingPricing" : "javascript:void(0)"} onClick={(() => islogin && !isPriceListingOwner ? nftBuyPopup(priceListing) : '')} className={"nft-buynow-btn" + (isPriceListingOwner ? " disable-button" : "")} title={isPriceListingOwner ? 'You own this item' : ''}>
                                                                            <img src={walletButton} />
                                                                            <h5 >{islogin ? 'BUY NOW' : <span data-toggle="modal" data-target="#exampleModalCenter">Connect Your Wallet First!</span>}</h5>
                                                                        </a>
                                                                    </>
                                                            }
                                                        </> : ''
                                                }
                                            </>
                                            :
                                            ""
                                            // <div className="nft-buynow-btn">
                                            //     <img src={walletButton} />
                                            //     <h5>{islogin ? 'BUY NOW1' :"Connect Your Wallet First!"}</h5>
                                            // </div>
                                        }
                                        {
                                            islogin ?
                                                <div className="edit-sell-bvtns">
                                                    {isEditable && !isMystery ?
                                                        <NavLink to={nft ? "/edit_nft/" + (nft.chain_name) + '/' + nft.contract_address + "/" + nft.token_id : ''}>EDIT</NavLink>
                                                        : ''}
                                                    {isMystery && isEditable ?
                                                        <NavLink to={nft ? "/edit-mystery-box/" + (nft.chain_name) + '/' + nft.contract_address + "/" + nft.token_id : ''}>EDIT</NavLink>
                                                        : ''}
                                                    {
                                                        isOwner && isActiveSellButton ?
                                                            <NavLink to={nft ? "/sell/" + (nft.chain_name) + "/" + nft.contract_address + "/" + nft.token_id : ''}>SELL</NavLink> : ''
                                                    }
                                                    {isCreator && !nft.is_partially_minted ?
                                                        // <NavLink to='#'  onClick={deleteNonmintedItem}>DELETE</NavLink> : ''                                                        
                                                        <span className='nft-buynow-btn'><h5 onClick={deleteNonmintedItem}>DELETE</h5></span> : ''
                                                    }
                                                    {isCreator && nft.is_partially_minted ?
                                                        <a href='javascript:void(0)' onClick={() => setBurnNftModal(true)} className='burn-color'>BURN</a>
                                                        : ''}
                                                </div> : ''
                                        }
                                        {islogin && !isOfferClosed && priceListing?.wallet_address?.toLowerCase() == auth?.user?.wallet_address?.toLowerCase() && isSell ?
                                            <div className="edit-sell-bvtns">
                                                {/* cancelSell(listing) */}
                                                <a onClick={() => !loading ? sellCancelConfirm(priceListing) : ''}>{!loading ? 'Cancel Sell' : 'Please Wait...'}</a>
                                            </div>
                                            : ''}
                                        {
                                           islogin && nftMarketItemQuantity && nftMarketItemQuantity['quantity'] > 0 ?
                                           <div className="edit-sell-bvtns">
                                                <a onClick={() => !loading ? cancelExpiredList() : ''}>{!loading ? 'Cancel expired list' : 'Please Wait...'}</a>
                                            </div>
                                            : ''
                                        }
                                        {/* <div className="details-info nft-properties d-flex justify-content-between align-items-center mb-3">
                                            <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                                <img src={priceHistory} />
                                                <h4>Price History</h4>
                                            </div>
                                            <img className="mr-3" src={SelectArrow} />
                                        </div>
                                        <div className="details-info nft-properties d-flex justify-content-between align-items-center mb-3">
                                            <div className="d-flex align-items-center justify-content-between gap-18 ml-3">
                                                <img src={makeOffer} />
                                                <h4>Offers</h4>
                                            </div>
                                            <img className="mr-3" src={SelectArrow} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 p-0 details-accordian-panel">
                            <div className="row">
                                <div className="col-sm-6 pl-0">
                                    <div id="accordion" className="SidebarAccordionFilters auction-edit-page">
                                        <div className="card">
                                            <div className="card-header" id="headingDesc">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseDesc" aria-expanded="true" aria-controls="collapseDesc">
                                                        <img src="/img/property-icon.png"></img> Properties
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseDesc" className="collapse show" aria-labelledby="headingDesc" data-parent="#accordion">
                                                <div className="card-body">
                                                    {nft && nft?.metadata?.attributes.length ?
                                                        <div className="nftproperty">
                                                            {nft?.metadata?.attributes.map((value, key) => (
                                                                !value.hasOwnProperty('max_value') ? 
                                                                <div className="PropertyCellreact__DivContainer-sc-1smi6j9-0 esyMuQ item--property" key={key}>
                                                                    <div className="Property--type">{value.trait_type}</div>
                                                                    <div className="Property--value" data-testid="Property--value">{value.value} {value.hasOwnProperty('max_value') ? ", " + value.max_value : ""}</div>
                                                                </div> : ''
                                                            ))}
                                                        </div>
                                                        : 'No Record Found!'}
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordion_label">
                                            <div className="card">
                                                <div className="card-header" id="headingDetailsLabel">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseDetailsLabel" aria-expanded="true" aria-controls="collapseDetailsLabel">
                                                            <img src="/img/levels.png"></img> Levels
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseDetailsLabel" className="collapse show" aria-labelledby="headingDetailsLabel" data-parent="#accordion_label">
                                                <div className="card-body">
                                                    {nft && nft?.metadata?.attributes.length ?
                                                        <div className="nftproperty">
                                                            {nft?.metadata?.attributes.map((value, key) => (
                                                                value.hasOwnProperty('max_value') ? 
                                                                <div key={key} className="nftcreatelevel">
                                                                    <div className="NumericTraitCellreact__DivContainer-sc-1vpqa3a-0 fnTiVF item--numeric-trait">
                                                                        <div className="NumericTrait--label">
                                                                            <div className="NumericTrait--type">
                                                                                {value.trait_type}
                                                                            </div>
                                                                            <div className="NumericTrait--value">
                                                                                {value.value} of {value.max_value}
                                                                            </div>
                                                                        </div>
                                                                        <div className="NumericTrait--bar">
                                                                            <div
                                                                                className="NumericTrait--bar-fill"
                                                                                style={{
                                                                                    width: `${100 * value.value / value.max_value}%`,
                                                                                }}
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ''
                                                            ))}
                                                        </div>
                                                        : 'No Record Found!'}
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordion_3">
                                            <div className="card">
                                                <div className="card-header" id="headingDetails">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseDetails" aria-expanded="true" aria-controls="collapseDetails">
                                                            <img src="/img/details-icon.png"></img> Details
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseDetails" className="collapse show" aria-labelledby="headingDetails" data-parent="#accordion_3">
                                                    <div className="card-body">
                                                        <div className="details-wrap-items">
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <h4>Contract Address</h4>
                                                                <a href={NetworkURLs[nft?.network + '_' + nft.network_chain_id]?.scanUrl + "/address/" + nft?.contract_address} target="_blank">{nft?.contract_address}</a>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <h4>Token ID</h4>
                                                                <a href={NetworkURLs[nft?.network + '_' + nft.network_chain_id]?.scanUrl + "/token/" + nft?.contract_address + "?a=" + nft?.token_id} target="_blank">{nft?.token_id}</a>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <h4>Token Standard</h4>
                                                                <h5>{nft?.token_standard}</h5>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <h4>Blockchain</h4>
                                                                <h5>{nft?.network_chain_label}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="card">
                                            <div className="card-header" id="headingDesc2">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseDesc2" aria-expanded="true" aria-controls="collapseDesc2">
                                                        DESCRIPTION
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseDesc2" className="collapse show" aria-labelledby="headingDesc2" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>{nft ? nft.description:''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingAboutColls">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseAboutColls" aria-expanded="true" aria-controls="collapseAboutColls">
                                                        ABOUT COLLECTION
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapseAboutColls" className="collapse show" aria-labelledby="headingAboutColls" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>
                                                        <img style={{height: '67px'}} className="align-left-pwrap" src={collection && collection.featured_resized_image ? collection.featured_resized_image : collection.featured_image} />
                                                        {collection ? collection.description:'No Record Found!'}                                                    
                                                    </p>
                                                    <div className="social-icons-links d-flex justify-content-start align-items-center gap-10 mt-4">
                                                        <a href="#"><svg className fill="#8A939B" viewBox="1 1 22 22" style={{ height: '20px', width: '20px' }}><path cliprule="evenodd" d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM15.97 8H18.92C17.96 6.35 16.43 5.07 14.59 4.44C15.19 5.55 15.65 6.75 15.97 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4 12C4 12.69 4.1 13.36 4.26 14H7.64C7.56 13.34 7.5 12.68 7.5 12C7.5 11.32 7.56 10.66 7.64 10H4.26C4.1 10.64 4 11.31 4 12ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM5.08 8H8.03C8.35 6.75 8.81 5.55 9.41 4.44C7.57 5.07 6.04 6.34 5.08 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM9.5 12C9.5 12.68 9.57 13.34 9.66 14H14.34C14.43 13.34 14.5 12.68 14.5 12C14.5 11.32 14.43 10.65 14.34 10H9.66C9.57 10.65 9.5 11.32 9.5 12ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.5 12C16.5 12.68 16.44 13.34 16.36 14H19.74C19.9 13.36 20 12.69 20 12C20 11.31 19.9 10.64 19.74 10H16.36C16.44 10.66 16.5 11.32 16.5 12Z" fillrule="evenodd" id="icon/action/language_24px" /></svg></a>
                                                        <a href="#"><svg className fill="#8A939B" viewBox="0 0 22 16" style={{ height: '20px', width: '20px' }}><path d="M8.11.5c-.28.002-2.574.067-4.996 1.873 0 0-2.584 4.665-2.584 10.408 0 0 1.507 2.593 5.473 2.719 0 0 .664-.792 1.202-1.477-2.278-.685-3.14-2.108-3.14-2.108s.18.126.502.307c.018 0 .035.019.07.036.055.035.108.054.162.09.448.252.896.45 1.31.611.736.307 1.615.576 2.639.774 1.346.252 2.925.342 4.646.019a12.954 12.954 0 002.603-.774 10.118 10.118 0 002.062-1.063s-.896 1.458-3.247 2.125c.538.666 1.185 1.439 1.185 1.439 3.965-.126 5.473-2.72 5.473-2.7 0-5.746-2.584-10.409-2.584-10.409C16.32.446 13.861.5 13.861.5l-.251.288c3.05.918 4.468 2.27 4.468 2.27a14.856 14.856 0 00-5.4-1.711 15.048 15.048 0 00-3.626.036c-.107 0-.197.019-.306.035-.628.072-2.153.288-4.073 1.135-.663.288-1.059.505-1.059.505S5.088 1.635 8.317.717L8.137.5h-.028zm-.457 6.645c1.022 0 1.849.882 1.83 1.981 0 1.1-.808 1.982-1.83 1.982-1.005 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981zm6.55 0c1.004 0 1.83.882 1.83 1.981 0 1.1-.809 1.982-1.83 1.982-1.006 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981z" /></svg></a>
                                                        <a href="#"><svg className fill="#8A939B" viewBox="0 0 24 16" style={{ height: '20px', width: '20px' }}><path cliprule="evenodd" d="M18.28 15.456c.317.168.725.21 1.09.107.363-.104.631-.337.712-.62.854-3.013 2.928-10.64 3.706-13.38.06-.207-.04-.421-.256-.56A1 1 0 0022.748.9C18.625 2.045 5.921 5.62.729 7.06c-.329.092-.543.33-.532.59.012.262.246.488.583.564 2.329.522 5.385 1.25 5.385 1.25s1.428 3.234 2.173 4.88c.093.206.309.369.593.425.283.055.586-.003.798-.153l3.046-2.157s3.513 1.933 5.506 2.997zM7.45 9.054L9.1 13.14l.367-2.587 10.02-6.778c.106-.072.12-.193.032-.278-.088-.085-.249-.104-.37-.047L7.45 9.054z" fillrule="evenodd" /></svg></a>
                                                        <a href="#"><svg className fill="#8A939B" viewBox="0 0 24 24" style={{ height: '20px', width: '20px' }}><path d="M15.75 2H8.25C4.79875 2 2 4.79875 2 8.25V15.75C2 19.2012 4.79875 22 8.25 22H15.75C19.2012 22 22 19.2012 22 15.75V8.25C22 4.79875 19.2012 2 15.75 2ZM20.125 15.75C20.125 18.1625 18.1625 20.125 15.75 20.125H8.25C5.8375 20.125 3.875 18.1625 3.875 15.75V8.25C3.875 5.8375 5.8375 3.875 8.25 3.875H15.75C18.1625 3.875 20.125 5.8375 20.125 8.25V15.75Z" /><path d="M12 7C9.23875 7 7 9.23875 7 12C7 14.7613 9.23875 17 12 17C14.7613 17 17 14.7613 17 12C17 9.23875 14.7613 7 12 7ZM12 15.125C10.2775 15.125 8.875 13.7225 8.875 12C8.875 10.2763 10.2775 8.875 12 8.875C13.7225 8.875 15.125 10.2763 15.125 12C15.125 13.7225 13.7225 15.125 12 15.125Z" /><path d="M17.375 7.29124C17.743 7.29124 18.0413 6.99295 18.0413 6.62499C18.0413 6.25703 17.743 5.95874 17.375 5.95874C17.0071 5.95874 16.7088 6.25703 16.7088 6.62499C16.7088 6.99295 17.0071 7.29124 17.375 7.29124Z" /></svg></a>
                                                        <a href="#"><svg className fill="#8A939B" viewBox="0 0 18 16" style={{ height: '20px', width: '20px' }}><path d="M.09 13.791c1.992.14 3.728-.344 5.327-1.571-.816-.098-1.527-.311-2.127-.786-.584-.466-1.032-1.047-1.272-1.841h1.48c.008-.033.016-.066.024-.107-.816-.237-1.512-.663-2.032-1.342-.52-.67-.775-1.448-.8-2.3.52.148 1.016.295 1.52.434.016-.033.04-.065.056-.098-.72-.606-1.24-1.334-1.431-2.275a3.92 3.92 0 01.391-2.7c2 2.389 4.511 3.715 7.598 3.936-.096-.778-.104-1.498.16-2.202.912-2.463 3.983-3.249 5.894-1.481.216.196.4.229.632.147.632-.229 1.255-.474 1.903-.72-.248.81-.784 1.408-1.415 1.989.615-.164 1.231-.336 1.839-.5.024.025.048.041.072.066-.464.491-.912 1.007-1.415 1.449-.272.237-.36.458-.376.818-.144 4.01-1.752 7.25-5.175 9.289-3.487 2.07-7.077 1.947-10.612-.025-.064-.04-.12-.09-.24-.18z" /></svg></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-sm-6 pr-0">
                                    <div id="accordion_1" className="SidebarAccordionFilters auction-edit-page">
                                        <div className="card">
                                            <div className="card-header" id="headingPricing">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapsePricing" aria-expanded="true" aria-controls="collapsePricing">
                                                        <img src={priceHistory} /> Price History
                                                    </button>
                                                </h5>
                                            </div>
                                            <div id="collapsePricing" className="collapse show" aria-labelledby="headingPricing" data-parent="#accordion_1">
                                                <div className="card-body">
                                                    {priceList && priceList?.length ?
                                                        <div className="table-outer-wrapper custom-scrollbar">
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Unit Price</th>
                                                                        <th>USD Unit Price</th>
                                                                        <th>Quantity</th>
                                                                        <th>Expiration</th>
                                                                        <th>From</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        priceList.map((row, index) => (
                                                                            <tr key={index}>
                                                                                <td className="text-center">
                                                                                    {
                                                                                        row?.price_unit === 'busd' ?
                                                                                            <span className={"busd-icon"}>{parseFloat(parseFloat(row?.start_price).toFixed(4))} BUSD</span> :
                                                                                            <ViewPrice network={network?.network} price={row?.start_price} />
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        row?.price_unit === 'busd' ?
                                                                                            <>
                                                                                                {row?.start_price.toFixed(4)} {" BUSD"}
                                                                                            </> :
                                                                                            <>
                                                                                                ${(row?.start_price * +network?.exchange_rate_usd).toFixed(2)} {" USD"}
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {row?.supply_to_sale}
                                                                                </td>
                                                                                <td>
                                                                                    {row?.end_date && moment(row?.end_date).fromNow() || ""}
                                                                                </td>
                                                                                <td>
                                                                                    {auth?.user?.wallet_address?.toLowerCase() == row?.wallet_address?.toLowerCase() ? 'you' : <WalletAddress wallet={row?.wallet_address} />}
                                                                                </td>
                                                                                <td className="whitespace-nowrap">
                                                                                    {/* cancelSell(row) */}
                                                                                    {auth?.user?.wallet_address?.toLowerCase() == row?.wallet_address?.toLowerCase() ? <a onClick={() => !loading ? sellCancelConfirm(row) : ''}>{!loading ? 'Cancel Sell' : 'Please Wait...'}</a> : row?.method === MethodName[1] ? '' : <a onClick={(() => islogin ? nftBuyPopup(row) : '')}>{islogin ? 'BUY NOW ' : <span data-toggle="modal" data-target="#exampleModalCenter">Connect Your Wallet First!</span>}</a>}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : 'No Record Found!'}
                                                </div>
                                            </div>
                                        </div>
                                        <div id="accordion_10">
                                            <div className="card">
                                                <div className="card-header" id="headingOffers">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseOffers" aria-expanded="true" aria-controls="collapseOffers">
                                                            <img src={OffersImage} /> Offers
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseOffers" className="collapse show" aria-labelledby="headingOffers" data-parent="#accordion_10">
                                                    <div className="card-body">
                                                        {priceBids?.totalBids ?
                                                            <div className="table-outer-wrapper custom-scrollbar">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Price</th>
                                                                            <th>USD Price</th>
                                                                            <th>Floor Difference</th>
                                                                            <th>Expiration</th>
                                                                            <th>From</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            priceBids?.bids ? priceBids?.bids.map((row, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <ViewPrice network={network?.network} price={row?.price} />
                                                                                    </td>
                                                                                    <td>${row?.price_usd.toFixed(2)}</td>
                                                                                    <td>{row?.floor_difference || ''}</td>
                                                                                    <td>{moment(row?.expire).fromNow()}</td>
                                                                                    <td><NavLink to={`/profile/${row?.user_id?.username}`}><WalletAddress wallet={row?.user_wallet} /></NavLink></td>
                                                                                    <td className="whitespace-nowrap">
                                                                                        {priceListing && priceListing?.wallet_address?.toLowerCase() === auth?.user?.wallet_address?.toLowerCase() ? <a onClick={() => !loading ? acceptBidRequest(row) : ''}>{!loading ? 'Accept' : 'Please Wait...'}</a> : ''}

                                                                                        {row?.user_wallet.toLowerCase() === auth?.user?.wallet_address?.toLowerCase() ? <a onClick={() => !loading ? cancelBidRequest(row) : ''}>{!loading ? 'Cancel' : 'Please Wait...'}</a> : ''}
                                                                                    </td>
                                                                                </tr>
                                                                            )) : ""
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                {
                                                                    priceBids.totalBids ? <Pagination page={priceBids.pageBid} onChange={(event, page) => fetchBids(page)} count={priceBids.last_page_bid} variant="outlined" shape="rounded" color="secondary" /> : ''
                                                                }
                                                                {/* {isOwner ? <div><NavLink to={nft ? "/offers/" + nft._id : ''}>See all</NavLink></div> : ''} */}
                                                            </div>
                                                            : 'No Record Found!'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5 p-0 details-accordian-panel">
                            <div id="accordion_2" className="SidebarAccordionFilters auction-edit-page">
                                <div className="card">
                                    <div className="card-header" id="headingActivityitems">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link d-flex align-items-center gap-18" data-toggle="collapse" data-target="#collapseActivityitems" aria-expanded="true" aria-controls="collapseActivityitems">
                                                <img src={NftActivity} /> NFT Activities
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseActivityitems" className="collapse show" aria-labelledby="headingActivityitems" data-parent="#accordion_2">
                                        <div className="card-body">
                                            <table className="table table-bordered table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Event</th>
                                                        <th>Price</th>
                                                        <th>From</th>
                                                        <th>To</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        nftActivities?.latestActivities.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.event}</td>
                                                                <td>
                                                                    {
                                                                        row.price && row.price_unit == 'BUSD' ?
                                                                            <>{row?.price} {" BUSD"}</> :
                                                                            row.price ?
                                                                                <ViewPrice network={network?.network} price={row?.price} /> : ''
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        row?.from_user ?
                                                                            <NavLink to={`/profile/${row?.from_user?.username}`}>
                                                                                {
                                                                                    row?.from_wallet ? <WalletAddress wallet={row?.from_wallet} /> : ''
                                                                                }
                                                                            </NavLink> :
                                                                            row?.from_wallet ? <WalletAddress wallet={row?.from_wallet} /> : ''
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        row?.to_user ?
                                                                            <NavLink to={`/profile/${row?.to_user?.username}`}>
                                                                                {
                                                                                    row?.to_wallet ? <WalletAddress wallet={row?.to_wallet} /> : ''
                                                                                }
                                                                            </NavLink> :
                                                                            row?.to_wallet ? <WalletAddress wallet={row?.to_wallet} /> : ''
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        row?.createdAt ? <span title={moment(row?.createdAt).format("MM-DD-YYYY HH:mm:ss")}>{moment(row?.createdAt).fromNow()}</span> : ""
                                                                    } &nbsp;
                                                                    {row?.transaction_hash ? <a target="blanck" href={`${row.url}/tx/${row.transaction_hash}`}><i className="fa fa-external-link" aria-hidden="true"></i></a> : ''}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            {nftActivities.totalActivities ? <Pagination page={nftActivities.pageActivity} onChange={(event, page) => fetchActivityList(page)} count={nftActivities.last_page_activity} variant="outlined" shape="rounded" color="secondary" /> : ''}
                                        </div>
                                        {islogin ?
                                            <div className="report-nft-wrap">
                                                <span onClick={() => setReportPopup(true)}><i className="fa fa-flag"></i> Report this NFT</span>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 view-collection-btn mt-10">
                                <NavLink to={nft?.collection_id ? "/collection/" + nft?.collection_id?.slug : ''}>View Collection</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <ModalSearch />
            <ModalMenu />
            {/* <Scrollup /> */}
            <Modal
                title="Offer"
                visible={offerVisibility}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
            >
                <MakeOfferPopup
                    saveOffer={((event) => saveOffer(event))}
                    setOfferPrice={(data) => setOfferPrice(data)}
                    datetime={datetime}
                    nft={nft}
                    setDateTime={(data) => setDateTime(data)}
                    setExpiration={(data) => setExpiration(data)}
                    expiration={expiration}
                />
            </Modal>
            <Modal
                title="Complete Checkout"
                visible={walletVisibility}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
            >
                <BuyNowNft
                    loading={loading}
                    saveOffer={((event) => saveOffer(event))}
                    setOfferPrice={(data) => setOfferPrice(data)}
                    datetime={datetime}
                    setDateTime={(data) => setDateTime(data)}
                    setExpiration={(data) => setExpiration(data)}
                    expiration={expiration}
                    nft={nft}
                    priceListingBuyNFT={priceListingBuyNFT}
                    network={network}
                    priceLogo={networkPriceLogo}
                    NftBuy={(data) => NftBuy(data)}
                    getEstimatedAmount={getEstimatedAmount}
                    handleCancel={handleCancel}

                />
            </Modal>
            <Modal
                title="Owner List"
                visible={ownerListPopup}
                onCancel={handleCancel}
                footer={null}
            >
                <NftOwnerList
                    list={ownerListDetails}
                />
            </Modal>
            <Modal
                title="Place Bid"
                visible={bidVisibility}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
            >
                <BidNft
                    handleCancel={() => { initAPIs(); handleCancel() }}
                    minPrice={crntprice}
                    nft={nft}
                    priceListing={priceListing}
                    networkLogo={networkPriceLogo}
                    network={network}
                />
            </Modal>


            {nft?._id ?
                <ShareContentModal
                    visible={showShareModal}
                    handleCancel={() => { setShowShareModal(false) }}
                    urlToShare={concatHostUrl(`${URLS.NFT_DETAILS}/${nft.network === 'polygon' ? 'matic' : nft.network}/${nft.contract_address}/${nft.token_id}`)}
                /> : null
            }
            {nft?._id ?
                <ReportModal nftDetails={{ nft_id: nft?._id, token_id: nft.token_id, contract_address: nft.contract_address, network: nft.network, chain_name: nft.chain_name }} isOpen={reportPopup} hideModal={() => setReportPopup(false)} getNftDetails={fetchNftDetails} />
                : null}


            <Modal
                className='nft_quick_report_wrp'
                title="NFT Transfer"
                visible={showTransferModal}
                onCancel={() => setShowTransferModal(false)}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
            >
                <TransferNft
                    authUser={auth?.user}
                    islogin={islogin}
                    loading={loading}
                    nft={nft}
                    ownerNft={isOwner}
                    nftTransfer={data => NftTransfer(data)}
                />
            </Modal>
            {/*<Modal
                title={<><i className="fa fa-lock" aria-hidden="true"></i>{' '}Unlockable Content</>}
                visible={showUnlockableContentModal}
                onCancel={() => setShowUnlockableContentModal(false)}
                footer={null}
                maskClosable={false}
            >
                <ViewUnlockableContent
                    data={nftUnlockableContent}
                    closeModal={() => handleCancel()}
                />
            </Modal>*/}
            {contract_address ?
                <UnlockContentModal
                    isOpen={showUnlockableContentModal}
                    isOwner={isOwner}
                    hideModal={() => setShowUnlockableContentModal(false)}
                    ref={unlockContentModalRef}
                    contractAddress={contract_address}
                    tokenId={token_id}
                />
                : null
            }
            <Modal
                className='nft_quick_report_wrp'
                title="NFT Burn"
                visible={burnNftModal}
                onCancel={() => setBurnNftModal(false)}
                footer={null}
                maskClosable={false}
                destroyOnClose={true}
            >
                <BurnNft
                    authUser={auth?.user}
                    nftBurn={data => NftBurn(data)}
                    loading={loading}
                >
                </BurnNft>
            </Modal>

        </div>
    );
}
const mapStateToProps = state => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    nftDetails: state.NFTDetails.nftDetails,
    isfetching: state.NFTDetails.isloading,
    networkList: state.networkList.networks
})

export default connect(mapStateToProps)(NftDetailPage);
