import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Formik, Form, Field } from "formik";
import { useHistory, useParams } from "react-router-dom";
import categoryIcon from "../../image/nft-form/Groupnft-form-icon-1.png";
import propIcon from "../../image/nft-form/Groupnft-form-icon-2.png";
import levelIcon from "../../image/nft-form/Groupnft-form-icon-3.png";
import unlockIcon from "../../image/nft-form/Groupnft-form-icon-4.png";
import sensitiveIcon from "../../image/nft-form/Groupnft-form-icon-5.png";
import GrouppopUpplus from "../../image/nft-form/GrouppopUpplus.png";
import nftTypeIcon from "../../image/nft-detail/current-time.png";
import previewIcon from "../../image/previewIcon.png";
import NftFormPopups from "../../themes/NftFormPopups";
import { Input, Checkbox, notification } from "antd";
import { SmileOutlined, CloseOutlined } from "@ant-design/icons";
import Web3 from "web3";
import LoginPopup from "../Header/LoginPopup";
import { NFTSchema } from "../../utils/Validation";
import loader from "../../image/loader.gif";
import Select from "react-select";
import { TokenStandard, MetadataDisplayType, NFT_FILE_MAX_SIZE } from "../../utils/app.constant";
//custom hooks
import useComman from "../../hooks/useComman";
import useNFT from "../../hooks/useNFT";
import useMedia from "../../hooks/useMedia";
import useNetworks from '../../hooks/useNetwork';
import { chainImageHandler } from "../../utils/helper";
import { fileExtensionCheck } from "../../utils/helper";

const web3 = new Web3(Web3.givenProvider);

function Edit({ props, islogin, auth, nftProps }) {
    const [file, setFile] = useState(null);
    const [uploadedFile, setUploadedFile] = useState("");
    const [catError, setCateError] = useState("");
    //   const [category, setCategory] = useState([]);
    //   const [categoryName, setCategoryName] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [propertyError, setPropertyError] = useState("");

    const [allProperties, setAllProperties] = useState([]);
    const [allLevels, setAllLevels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [explicity, setExplixity] = useState(false);
    const [enableLazyMinting, setEnableLazyMinting] = useState(false);
    const [unlockable, setUnlockable] = useState(false);
    const [formReset, setFormSet] = useState(false);
    const [fileShowType, setFileShowType] = useState("image");
    const { network, contract_address, token_id } = useParams();
    const [collectionSelected, setCollectionSelected] = useState(false);
    const [collection, setCollection] = useState([]);
    const [fileType, setFileTypeError] = useState("");
    const [loading, setLoading] = useState(false);
    const [fileExtension, setFileExtension] = useState("");

    const [nftDetails, setNftDetails] = useState('')
    const [itemName, setItem] = useState("");
    const [description, setDescription] = useState("");
    const [isOwner, setOwner] = useState(false);
    const [selectCollectionErr, setselectCollectionErr] = useState("");
    // const [isSaveProperty, setIsSaveProperty] = useState(false);
    const [isSubmiting, setIsSubmitting] = useState(false);
    const [isSaveLevel, setIsSaveLevel] = useState(false);
    // const [compareCategory, setCompareCategory] = useState([]);
    const ref = useRef(false);
    const history = useHistory();
    const properties = { type: "", name: "" };
    const levelDefault = { name: "", value_one: 3, value_two: 5 };
    const { categorieIds, categories } = useComman();
    const [networkList, setNetworkList] = useState([]);
    const [networkSelected, setNetworkSelected] = useState(null);
    const [freezeMetadata, setFreezeMetadata] = useState(false)

    //custom hooks
    const { collectionsOfUser } = useComman();
    const { getNft, getNftDetails, update } = useNFT();
    const { FileUpload } = useMedia();
    const { getNetworkList } = useNetworks();

    // useEffect(async () => {
    //     getNFT();
    //     let catRes = await categories();
    //     // setCompareCategory(catRes);
    // }, []);

    // useEffect(() => {

    // }, [allProperties, categoryName])
    useEffect(() => {
        updateLocalData();
        /*if (nftDetails && nftDetails?.properties) {
              setAllProperties(nftDetails?.properties);
            }*/
    }, [nftDetails]);

    useEffect(async () => {
        getNFT();
        categories();
        getCollections();
        fetchNetwork();
        ref.current = true;
        return () => {
            ref.current = false;
        };
    }, []);

    useEffect(() => {
        if (networkList.length > 0) {
            let filterNetwork = networkList.filter((value) => value.network === nftDetails.network);
            // console.log('filter network', filterNetwork);
            setNetworkSelected(filterNetwork)
        }
    }, [networkList])

    const getNFT = async () => {
        var nft = await getNftDetails({ network, contract_address, token_id });
        setNftDetails(nft.nft_items);
    };

    const getCollections = async () => {
        var cate = await collectionsOfUser();
        await setCollection(cate);
    };

    const fetchNetwork = async () => {
        const fetchNetwork = await getNetworkList();
        setNetworkList(fetchNetwork.networks);
        if (fetchNetwork.networks.length) {
            setNetworkSelected(fetchNetwork.networks[0]["_id"]);
        }
    };

    const updateLocalData = () => {
        // console.log('nft details', nftDetails);
        if (nftDetails) {
            setExplixity(nftDetails?.explicit);
            setEnableLazyMinting(nftDetails?.enable_lazy_minting)
            setFreezeMetadata(nftDetails?.is_freezed_metadata)
            setAllProperties(
                nftDetails?.properties ? nftDetails?.properties : allProperties
            );
            setAllLevels(nftDetails?.levels ? nftDetails?.levels : allLevels);
            setCollectionSelected(nftDetails?.collection_id?._id);
            setFile(nftDetails && nftDetails.item_resized_image? nftDetails.item_resized_image : nftDetails.item_image);
            setItem(nftDetails?.item_name);
            setDescription(nftDetails?.description);
            setUnlockable(nftDetails?.unlockable)
            /*nftDetails && nftDetails.categories_id && nftDetails?.categories_id.map((cat) => {
                      selectCategory(cat, true);
                  });*/
            const categories =
                nftDetails &&
                nftDetails.categories_id &&
                nftDetails?.categories_id.map((cat) => ({
                    _id: cat["_id"],
                    name: cat["name"],
                }));
            setSelectedCategories(categories);

            if (networkList.length > 0) {
                let filterNetwork = networkList.filter((value) => value.network === nftDetails.network);
                setNetworkSelected(filterNetwork)
            }
        }
    };

    const GetNeft = async () => {
        await setIsLoading(true);
        var crntuser = auth?.user;
        var nft = await getNft({ network, contract_address, token_id });

        if (nft) {
            if (!nft.isEditable) {
                history.push(`/assets/${nft.chain_name}/${nft.contract_address}/${nft.token_id}`);
            }
            await setExplixity(nft?.explicit);
            setEnableLazyMinting(nftDetails?.enable_lazy_minting)
            // await setCategory(nft?.categories_id);
            await setAllProperties(nft?.properties ? nft?.properties : allProperties);
            await setAllLevels(nft?.levels ? nft?.levels : allLevels);
            await setCollectionSelected(nft?.collection_id?._id);
            await setFile(nft?.item_image);
            await setItem(nft?.item_name);
            await setDescription(nft?.description);

            // await setNftDetails(nft)
        }
        await setIsLoading(false);
    };

    const reInitModal = () => {
        setSelectedCategories(JSON.parse(JSON.stringify(selectedCategories)));
        setAllProperties(JSON.parse(JSON.stringify(allProperties)));
        setAllLevels(JSON.parse(JSON.stringify(allLevels)));
    };

    // const selectFile = async (e) => {
    //     console.log("e",e)
    //     const reader = new FileReader();
    //     if (e.target.files && e.target.files.length > 0) {
    //         setFile(loader)
    //         var form = new FormData()
    //         form.append('image', e.target.files[0])
    //         var fileRes = await FileUpload(form)
    //         reader.addEventListener("load", () => setFile(reader.result));
    //         await setUploadedFile(fileRes);
    //     }
    //     reader.readAsDataURL(e.target.files[0]);
    // }

    const selectFile = async (e) => {
        setLoading(true);
        const reader = new FileReader();

        if (e.target.files && e.target.files.length > 0) {
            if (NFT_FILE_MAX_SIZE < e.target.files[0].size) {
                setFileTypeError("You can upload 100MB size file!");
                return;
            }

            let fileTypeName = e.target.files[0].name.split('.');
            let fileExt = fileTypeName[fileTypeName.length - 1].toLowerCase();

            if (!fileExtensionCheck.includes(fileExt)) {
                setFileTypeError("Not valid file!, Please upload (jpg, jpeg, png, gif, svg, mp4, mov, wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb, gltf, ogg, glb, gltf,hevc, h.264 file type");
                return;
            }

            // if (
            //     !e.target.files[0].type.match(
            //         /.(jpg|jpeg|png|gif|mp4|mov|wmv|wav|avi|avchd|mkv|webm|mpeg|mp3|glb|gltf)$/i
            //     )
            // ) {
            //     setFileTypeError(
            //         "Not valid file!, Please upload (jpg, jpeg, png, gif, mp4, mov, wmv, wav, avi, avchd, mkv, webm, mpeg, mp3, glb, gltf) file type"
            //     );
            //     return;
            // }

            setFile(loader);
            setFileExtension(e.target.files[0].type);
            var form = new FormData();
            form.append("image", e.target.files[0]);
            var fileRes = await FileUpload(form);

            if (fileRes) {
                setFileShowType(e.target.files[0].type.slice(0, 5));
                reader.addEventListener("load", () => setFile(reader.result));
                await setUploadedFile(fileRes);
                setFileTypeError("");
            } else {
                notification.open({
                    message: "Error",
                    description: "This file not supported!",
                    icon: <CloseOutlined style={{ color: "#f4364c" }} />,
                });
                setFileTypeError("This file not supported!");
                setFile(previewIcon);
                setLoading(false);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setLoading(false);
    };
    // const selectCategory = async (newCate, status) => {
    //     if (!status) {
    //         var old = category.filter((value) => value !== newCate)
    //         await setCategory(old)
    //     } else {
    //         var old = category
    //         console.log('category', category)
    //         return
    //         old.push(newCate)
    //         await setCategory(old)
    //     }
    // }

    /*const selectCategory = async (newCate, status, name) => {
        console.log(
          "newCate",
          newCate,
          "catname",
          name,
          "status",
          status,
          "cat",
          category
        );
        setLoading(true);
        setCategory(nftDetails?.categories_id);
        if (!status) {
          console.log("one");
          var old = category.filter((value) => value !== newCate);
          console.log("old", old);
    
          var cate = categoryName.filter((valueName) => valueName !== name);
          await setCategory(old);
          await setCategoryName(cate);
        } else {
          console.log("two");
          console.log("categorytw", category);
    
          var old = category;
          console.log("elseold", old);
    
          var cateName = categoryName;
          console.log("cateName", cateName);
    
          old.push(newCate);
          console.log("pushold", old);
          cateName.push(name);
          console.log("pushcateName", cateName);
    
          await setCategory(old);
          await setCategoryName(cateName);
        }
        setLoading(false);
      };*/

    /*const selectCategory = async (cat, status) => {
          const gotIndex = selectedCategories.findIndex(
              (e) => e["_id"] == cat["_id"]
          );
          if (gotIndex != -1) {
              if (!status) {
                  var r = selectedCategories;
                  r.splice(gotIndex, 1);
                  console.log('selectedCategories: ', r)
                  setSelectedCategories(r);
              }
          } else {
              if (status) {
                  setSelectedCategories((prevCats) => {
                      var r = [
                          ...prevCats,
                          { _id: cat["_id"], name: cat["name"] },
                      ];
                      console.log('selectedCategories: ', selectedCategories)
                      return r;
                  });
                  // var r = [ ...selectedCategories, { _id: cat["_id"], name: cat["name"] } ]
                  // console.log('selectedCategories: ', r)
                  // setSelectedCategories(r)
              }
          }
      };*/
    const selectCategory = async (categories) => {
        setSelectedCategories(categories);
        if (categories.length > 0) {
            setCateError('')
        } else {
            setCateError('Please select any one category!')
        }
    };
    const setProperty = async (property) => {
        setAllProperties(property.filter(item => item.type !== '' && item.name !== ''));
    };
    const setLabel = async (labels) => {
        setAllLevels(labels.filter(item => item.name !== '' && item.value_one > 0 && item.value_two > 0));
    };

    /*const handelProperty = async (data, index) => {
          var all = allProperties;
          var newData = { ...all[index], ...data };
          all[index] = newData;
          await setAllProperties(all);
      };
  
      const addProperty = async () => {
          await setIsLoading(true);
          let prop = allProperties;
          prop.push(properties);
          setAllProperties(prop);
          await setIsLoading(false);
      };
  
      const removeProperty = async (index) => {
          await setIsLoading(true);
          var old = allProperties;
          var newArray = old.filter((value, key) => {
              if (key !== index) {
                  return value;
              }
          });
          await setAllProperties(newArray);
          await setIsLoading(true);
      };*/

    const handelLevel = async (data, index) => {
        var all = allLevels;
        var newData = { ...all[index], ...data };
        all[index] = newData;
        await setAllLevels(all);
    };

    const addLevel = async () => {
        await setIsLoading(true);
        var lev = allLevels;
        lev.push(levelDefault);
        setAllLevels(lev);
        await setIsLoading(false);
    };

    const removeLevel = async (index) => {
        await setIsLoading(true);
        var old = allLevels;
        var newArray = old.filter((value, key) => {
            if (key !== index) {
                return value;
            }
        });
        await setAllLevels(newArray);
        await setIsLoading(true);
    };

    const createNft = async (data) => {
        await setIsLoading(true);
        await setCateError("");
        if (selectedCategories.length === 0) {
            setCateError("Please select any one category!");
            return;
        }

        var user = auth?.user;
        var coll = collectionSelected;

        setIsSubmitting(true);
        data.explicit = explicity;
        data.enable_lazy_minting = enableLazyMinting;
        data.unlockable = unlockable;
        data.properties = allProperties;
        data.levels = allLevels;
        data.item_image = uploadedFile ? uploadedFile['actual_file_path'] : file;
        data.item_resized_image = uploadedFile['resized_path'] || nftDetails.item_resized_image || null;
        // data.contract_address = user.wallet_address;
        // data.collection_id = coll ? coll : "";
        data.categories_id = selectedCategories.map((e) => e["_id"]);
        data.is_freezed_metadata = freezeMetadata;

        const attributeProperties =
            (data["properties"] &&
                data["properties"].length &&
                data["properties"].map((p) => {
                    if (
                        p["displayType"] &&
                        ![MetadataDisplayType.TEXT, MetadataDisplayType.READ_ONLY].includes(
                            p["displayType"]
                        )
                    ) {
                        return {
                            display_type: p["displayType"],
                            trait_type: p["type"],
                            value: p["name"],
                        };
                    } else {
                        return {
                            trait_type: p["type"],
                            value: p["name"],
                        };
                    }
                })) ||
            [];
        const attributeLevels =
            (data["levels"] &&
                data["levels"].length &&
                data["levels"].map((p) => {
                    if (
                        p["displayType"] &&
                        ![MetadataDisplayType.TEXT, MetadataDisplayType.READ_ONLY].includes(
                            p["displayType"]
                        )
                    ) {
                        return {
                            display_type: p["displayType"],
                            trait_type: p["name"],
                            value: p["value_1"],
                            max_value: p["value_2"],
                        };
                    } else {
                        return {
                            trait_type: p["name"],
                            value: p["value_one"],
                            max_value: p["value_two"],
                        };
                    }
                })) ||
            [];
        /*const attributeCategory = selectedCategories.map(cat => ({
            trait_type: "Category",
            value: cat['name'] || ""
        }))*/

        let metaurl = {
            name: data["item_name"],
            image: data["item_image"],
            description: data["description"],
            attributes: [...attributeProperties, ...attributeLevels],
        };
        data.metadata = metaurl;

        if (!coll) {
            delete data.collection_id;
        }

        var res = await update(
            data,
            nftDetails.chain_name,
            nftDetails.contract_address,
            nftDetails.token_id
        );

        if (res) {
            notification.open({
                message: "NFT",
                description: "NFT Successfully Updated...!",
                icon: <SmileOutlined style={{ color: "#108ee9" }} />,
            });
            setIsSubmitting(false);
            window.localStorage.removeItem("collectionName");
            history.push(`/assets/${res.nft_items.chain_name}/${res.nft_items.contract_address}/${res.nft_items.token_id}`);
        } else {
            notification.open({
                message: "Error",
                description: "Something went wrong...!",
                icon: <CloseOutlined style={{ color: "#f4364c" }} />,
            });
            setIsSubmitting(false);
        }
    };

    // console.log('ppp=>', nftDetails, Object.keys(nftDetails).length);
    if (Object.keys(nftDetails).length > 0) {
        if (nftDetails?.isEditable && nftDetails?.user_wallet?.toLowerCase() === auth?.user.wallet_address.toLowerCase() && (!nftDetails?.is_freezed_metadata || (!nftDetails?.is_partially_minted && nftDetails?.is_freezed_metadata)) ) {
            // return <PageNotFound text={'NFT Not Found'} />
        } else {
            history.push(`/assets/${nftDetails?.network === 'polygon'? 'matic' : nftDetails?.network}/${nftDetails?.contract_address}/${nftDetails?.token_id}`);
        }
    }


    return (
        <>
            <div className="games__wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-1 mx-0 px-0"></div>
                        <div className="col-2 row align-items-end flex-column text-left d-none"></div>
                        <div className="col-9 p-0 res form-transp-bg">
                            <h3 className="oracleTextClass">Edit NFT</h3>

                            {!islogin ? (
                                <div className="pt-lg-5">
                                    <p className="connect-wallet-message">*Please connect your wallet to continue.</p>
                                    <button
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        className="connect-wallet-btn"
                                    >
                                        Connect Your Wallet
                                    </button>
                                </div>
                            ) : (
                                <Formik
                                    initialValues={{
                                        item_image: nftDetails && nftDetails.item_resized_image ? nftDetails.item_resized_image : nftDetails.item_image || "",
                                        item_profile: nftDetails ? nftDetails?.item_image : "",
                                        item_name: nftDetails ? nftDetails?.item_name : "",
                                        collection_id: nftDetails ? nftDetails?.collection_id?._id : collection.length ? collection[0]['_id'] : '',

                                        description: nftDetails ? nftDetails?.description : "",
                                        explicit: nftDetails ? nftDetails.explicit : "",
                                        unlockable_link: nftDetails ? nftDetails.unlockable_link : "",
                                        external_link: nftDetails ? nftDetails.external_link : "",
                                        supply: nftDetails ? nftDetails?.supply : ''
                                    }}
                                    enableReinitialize
                                    validationSchema={NFTSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        createNft(values);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        /* and other goodies */
                                    }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            className="item-form createNftForm edit-form no-hover"
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>
                                                        <span className="required-class">*</span> Required
                                                        Fields
                                                    </p>

                                                    <div className="input-group form-group mt-4 mb-0">
                                                        <div className="custom-file-old">
                                                            <label className="image-upload-lbl mb-2">
                                                                Image, Video, Audio, or 3D Model
                                                                <span className="required-class caution">
                                                                    * Max size: 100 MB
                                                                </span>{" "}
                                                            </label>
                                                            <p>
                                                                {" "}
                                                                (File types supported:jpg, jpeg, png, gif, mp4,
                                                                mov, wmv, wav, avi, avchd, mkv, webm, mpeg, mp3,
                                                                glb, gltf ){" "}
                                                            </p>
                                                            <label
                                                                className="custom-file-label-old col-6"
                                                                htmlFor="inputGroupFile01"
                                                            >
                                                                <div className="image-preview-box cnftimg add-image">
                                                                    {/* {console.log('details=>', nftDetails)} */}
                                                                    {nftDetails.file_extension === 'image' ? (
                                                                        <img
                                                                            src={file || nftDetails?.item_image}
                                                                            name="item_profile"
                                                                        />
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {nftDetails.file_extension === 'video' ? (
                                                                        <video
                                                                            playsInline
                                                                            muted
                                                                            autoPlay={true}
                                                                            controlsList="nodownload"
                                                                            loop=""
                                                                            preload="auto"
                                                                            src={file || nftDetails?.item_image}
                                                                            name="item_profile"
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "calc(100% - 50px",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        ''
                                                                    )}

                                                                    {nftDetails.file_extension === 'audio' ? (
                                                                        <audio controls>
                                                                            <source src={file || nftDetails?.item_image} />
                                                                        </audio>
                                                                    ) : (
                                                                        ''
                                                                    )}

                                                                </div>
                                                            </label>

                                                            <input
                                                                name="item_image"
                                                                onChange={(event) => selectFile(event)}
                                                                type="file"
                                                                className="custom-file-input-none"
                                                                id="inputGroupFile01"
                                                            />
                                                        </div>
                                                        <button className="create-btn new-upload-btn mt-3 mt-sm-4 mb-5" type="button">
                                                            <label for="inputGroupFile01">Upload File</label>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>
                                                            Item name<span className="required-class">*</span>{" "}
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="nft-form-control"
                                                            name="item_name"
                                                        />
                                                        {errors.item_name && touched.item_name ? (
                                                            <div className="text-danger">{errors.item_name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>External link </label>
                                                        <p>
                                                            EGC will included a link you can share and promote
                                                            your NFT. You can link your own webpage.
                                                        </p>
                                                        <Field
                                                            type="text"
                                                            className="nft-form-control"
                                                            name="external_link"
                                                        />
                                                        {errors.external_link && touched.external_link ? (
                                                            <div className="text-danger">{errors.external_link}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>Description</label>
                                                        <p>This will be included on your item's detail.</p>
                                                        <Field as="textarea" name="description"></Field>
                                                    </div>
                                                </div>

                                                <div className="flex-column w-100 px-3 mb-4">
                                                    <h3>COLLECTION</h3>
                                                    <p className="mb-3 mt-1">
                                                        This is the collection where your item will appear.
                                                    </p>

                                                    <div className="d-flex justify-content-start align-items-start gap-10">
                                                        <Field as="select" name="collection_id">
                                                            {collection.length > 0
                                                                ? collection.map((value, key) => (
                                                                    <option key={key} value={value._id}>
                                                                        {value.collection_name}
                                                                    </option>
                                                                ))
                                                                : ""}
                                                        </Field>
                                                        {/* <select
                                                            defaultValue={
                                                                nftDetails?.collection_id?.collection_name
                                                            }
                                                            onChange={(event) =>
                                                                setCollectionSelected(event.target.value)
                                                            }
                                                        >
                                                            {collection && collection?.length > 0
                                                                ? collection?.map((value, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            selected={console.log(
                                                                                collectionSelected === value._id
                                                                                    ? "selected"
                                                                                    : collectionSelected
                                                                            )}
                                                                            value={value._id}
                                                                        >
                                                                            {value.collection_name}
                                                                        </option>
                                                                    );
                                                                })
                                                                : ""}
                                                        </select> */}
                                                    </div>
                                                        {errors.collection_id && touched.collection_id ? (
                                                            <div className="text-danger">{errors.collection_id}</div>
                                                        ) : null}
                                                    {/* <div style={{ color: "red" }}>
                                                        {selectCollectionErr}
                                                    </div> */}
                                                </div>

                                                <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <img className="imgw-30" src={categoryIcon} /> */}

                                                        <div className="flex-column justify-content-between align-items-baseline">
                                                            <h3>
                                                                <i className="fa fa-th-list"></i> Select one or
                                                                more categories
                                                                <span className="required-class">*</span>
                                                            </h3>
                                                            <div style={{ color: "red" }}>{catError}</div>
                                                            <p>
                                                                {selectedCategories &&
                                                                    selectedCategories?.length > 0
                                                                    ? selectedCategories?.map((cat, key) => (
                                                                        <span key={key}>{cat.name}, </span>
                                                                    ))
                                                                    : ""}{" "}
                                                                {selectedCategories &&
                                                                    selectedCategories.length > 0
                                                                    ? ".etc"
                                                                    : ""}{" "}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                        <img
                                                            className="imgw-30 cursor-pointer"
                                                            data-toggle="modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            data-target="#categoryModal"
                                                            src={GrouppopUpplus}
                                                            onClick={reInitModal}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <img className="imgw-30" src={propIcon} /> */}
                                                        <div className="flex-column justify-content-between align-items-baseline">
                                                            <h3>
                                                                <i className="fa fa-building"></i>Properties
                                                            </h3>
                                                            <p>Textual traits will show up as rectangles.</p>
                                                        </div>
                                                    </div>

                                                    <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                        <img
                                                            className="imgw-30 cursor-pointer"
                                                            src={GrouppopUpplus}
                                                            data-toggle="modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            data-target="#propertyModal"
                                                            onClick={reInitModal}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="nftcreateproperty">
                                                    {allProperties?.map((value, key) => {
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="PropertyCellreact__DivContainer-sc-1smi6j9-0 esyMuQ item--property"
                                                            >
                                                                <div className="Property--type">
                                                                    {value.type}
                                                                </div>
                                                                <div
                                                                    className="Property--value"
                                                                    data-testid="Property--value"
                                                                >
                                                                    {value.name}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {/* {isSaveProperty
                                                        ? allProperties?.map((value, key) => (
                                                            <div key={key} className="PropertyCellreact__DivContainer-sc-1smi6j9-0 esyMuQ item--property">
                                                                <div className="Property--type">
                                                                    {value.type}
                                                                </div>
                                                                <div
                                                                    className="Property--value"
                                                                    data-testid="Property--value"
                                                                >
                                                                    {value.name}
                                                                </div>
                                                            </div>
                                                        ))
                                                        : allProperties?.map((value, key) => (
                                                            <div key={key} className="PropertyCellreact__DivContainer-sc-1smi6j9-0 esyMuQ item--property">
                                                                <div className="Property--type">
                                                                    {value.type}
                                                                </div>
                                                                <div
                                                                    className="Property--value"
                                                                    data-testid="Property--value"
                                                                >
                                                                    {value.name}
                                                                </div>
                                                            </div>
                                                        ))} */}

                                                    <div>{propertyError}</div>
                                                </div>

                                                <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <img className="imgw-30" src={levelIcon} /> */}
                                                        <div className="flex-column justify-content-between align-items-baseline">
                                                            <h3>
                                                                <i className="fa fa-list-ol"></i> Levels
                                                            </h3>
                                                            <p>Numerical traits will show as progress bar.</p>
                                                        </div>
                                                    </div>

                                                    <div className="rounded-circle-bg d-flex align-items-center justify-content-center">
                                                        <img
                                                            className="imgw-30 cursor-pointer"
                                                            src={GrouppopUpplus}
                                                            data-toggle="modal"
                                                            data-backdrop="static"
                                                            data-keyboard="false"
                                                            data-target="#levelsModal"
                                                            onClick={reInitModal}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="nftlevel">
                                                    {allLevels.map((value, key) => (
                                                        <div key={key} className="nftcreatelevel">
                                                            <div className="NumericTraitCellreact__DivContainer-sc-1vpqa3a-0 fnTiVF item--numeric-trait">
                                                                <div className="NumericTrait--label">
                                                                    <div className="NumericTrait--type">
                                                                        {value.name}
                                                                    </div>
                                                                    <div className="NumericTrait--value">
                                                                        {value.value_one} of {value.value_two}
                                                                    </div>
                                                                </div>
                                                                <div className="NumericTrait--bar">
                                                                    <div
                                                                        className="NumericTrait--bar-fill"
                                                                        style={{
                                                                            width: `${100 * value.value_one / value.value_two}%`,
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* {isSaveLevel
                                                        ? allLevels.map((value, key) => (
                                                            <div key={key} className="nftcreatelevel">
                                                                <div className="NumericTraitCellreact__DivContainer-sc-1vpqa3a-0 fnTiVF item--numeric-trait">
                                                                    <div className="NumericTrait--label">
                                                                        <div className="NumericTrait--type">
                                                                            {value.name}
                                                                        </div>
                                                                        <div className="NumericTrait--value">
                                                                            {value.value_one} of {value.value_two}
                                                                        </div>
                                                                    </div>
                                                                    <div className="NumericTrait--bar">
                                                                        <div
                                                                            className="NumericTrait--bar-fill"
                                                                            style={{
                                                                                width: `${20 * value.value_one}%`,
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : allLevels?.map((value, key) => (
                                                            <div key={key} className="nftcreatelevel">
                                                                <div className="NumericTraitCellreact__DivContainer-sc-1vpqa3a-0 fnTiVF item--numeric-trait">
                                                                    <div className="NumericTrait--label">
                                                                        <div className="NumericTrait--type">
                                                                            {value.name}
                                                                        </div>
                                                                        <div className="NumericTrait--value">
                                                                            {value.value_one} of {value.value_two}
                                                                        </div>
                                                                    </div>
                                                                    <div className="NumericTrait--bar">
                                                                        <div
                                                                            className="NumericTrait--bar-fill"
                                                                            style={{
                                                                                width: `${20 * value.value_one}%`,
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))} */}
                                                </div>

                                                <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <imallPropertiesg
                                                            className="imgw-30"
                                                            src={unlockIcon}
                                                        /> */}
                                                        <div className="flex-column justify-content-between align-items-baseline">
                                                            <h3>
                                                                <i className="fa fa-unlock-alt"></i>Unlockable
                                                                Content
                                                            </h3>
                                                            <p>Content that can only be seen by the owner.</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <label className="switch switch-radio-class">
                                                            <input
                                                                onChange={(event) =>
                                                                    setUnlockable(event.target.checked)
                                                                }
                                                                checked={unlockable}
                                                                name="unlockable"
                                                                type="checkbox"
                                                            />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>

                                                {unlockable && (
                                                    <div className="col-12">
                                                        <div className="form-group mt-3">
                                                            <Field
                                                                as="textarea"
                                                                name="unlockable_link"
                                                                placeholder="Enter content (access key, code to redeem, link to a file, etc.)"
                                                            ></Field>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {/* <img className="imgw-30" src={sensitiveIcon} /> */}
                                                        <div className="flex-column justify-content-between align-items-baseline ml-3">
                                                            <h3>
                                                                <i className="fa fa-exclamation-triangle"></i>{" "}
                                                                Explicit & Sensitive Content
                                                            </h3>
                                                            <p>Set as explicit and sensitive content.</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <label className="switch switch-radio-class">
                                                            <input
                                                                checked={explicity ? 'checked' : ''}
                                                                onChange={(event) =>
                                                                    setExplixity(event.target.checked)
                                                                }
                                                                name="explicit"
                                                                type="checkbox"
                                                            />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="flex-column justify-content-between align-items-baseline ml-3">
                                                            <h3>
                                                                <i className="fa fa-gavel" aria-hidden="true"></i>{" "}
                                                                Enable Lazy Minting
                                                            </h3>
                                                            <p>Set as enable lazy minting.</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <label className="switch switch-radio-class">
                                                            <input
                                                                checked={enableLazyMinting ? 'checked' : ''}
                                                                onChange={(event) =>
                                                                    setEnableLazyMinting(event.target.checked)
                                                                }
                                                                name="enable_lazy_minting"
                                                                type="checkbox"
                                                                disabled={true}
                                                            />
                                                            <span className="slider round" />
                                                        </label>
                                                    </div>
                                                </div>

                                                                    {/* ********************************************************************* */}
                    <div className="nftform-action-item col-12 d-flex justify-content-between align-items-baseline">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="flex-column justify-content-between align-items-baseline">
                          <h3 className="top-adjust">
                            Freeze metadata {" "}
                            <i class="fa fa-info-circle"></i>
                          </h3>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <label className="switch switch-radio-class">
                          <input
                          checked={freezeMetadata ? 'checked' : ''}
                            onChange={(event) =>
                              setFreezeMetadata(event.target.checked)
                            }
                            name="freeze_metadata"
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>

                    {/* ********************************************************************** */}


                                                <div className="col-12">
                                                    <div className="form-group mt-3">
                                                        <label>
                                                            Supply<span className="required-class">*</span>{" "}
                                                        </label>
                                                        <p>
                                                            The number of items that can be minted. No gas cost to
                                                            you!
                                                        </p>
                                                        <Field type="number" className="nft-form-control" name="supply" onWheel={(e) => e.target.blur()} disabled={nftDetails?.is_partially_minted}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex-column w-100 px-3 mb-4">
                                                    <h3 className="title-inner">Blockchain</h3>
                                                    <p className="mb-3 mt-1 small-para">Select the blockchain where you'd like new items from this collection to be added by default.</p>
                                                    <div className="d-flex justify-content-start align-items-start gap-10 cstom-select-box1">
                                                    </div>

                                                    <Select
                                                        getOptionLabel={(opt) => (
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={chainImageHandler(opt?.network)}
                                                                    height="35px"
                                                                    width="35px"
                                                                />

                                                                <span className="d-block ml-2">
                                                                    {opt?.chain_label}
                                                                </span>
                                                            </div>
                                                        )}
                                                        value={networkSelected}
                                                        placeholder={'Select Network..'}
                                                        getOptionValue={(option) => option._id}
                                                        options={[networkSelected]}
                                                        // onChange={handleChange}
                                                        // onChange={(e) => { setNetworkSelected(e) }}
                                                        menuPosition="absolute"
                                                        menuPortalTarget={document.body}
                                                        isDisabled={true}
                                                    />
                                                    {/* <div style={{ color: 'red' }}>{selectCollectionErr}</div> */}
                                                </div>

                                                <div className="col-12">
                                                    <button
                                                        type="submit"
                                                        className="create-btn mt-3 mt-sm-4 mb-5"
                                                    // disabled={isSubmitting}
                                                    >
                                                        {isSubmiting ? "Please Wait" : "Submit"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <NftFormPopups
                // selectCategory={((event, status) => selectCategory(event, status))}
                returnCategory={(categories) => selectCategory(categories)}
                selectedCategories={selectedCategories}
                // setProperties={(event, index) => (event, index)}
                returnProperty={(property) => setProperty(property)}
                allProperties={allProperties}
                // addProperty={() => addProperty()}
                // removeProperty={(index) => removeProperty(index)}
                // setIsSaveProperty={setIsSaveProperty}

                allLevels={allLevels}
                returnLabel={(label) => setLabel(label)}
                // addLevel={() => addLevel()}
                // handelLevel={(event, index) => handelLevel(event, index)}
                // removeLevel={(index) => removeLevel(index)}
                // setIsSaveLevel={setIsSaveLevel}

                nftDetails={nftDetails}
            />
            <LoginPopup />
        </>
    );
}
// const mapStateToProps = state => ({
//     islogin:state.auth.isLogin,
//     auth:state.auth.auth
// })

const mapStateToProps = (state) => ({
    islogin: state.auth.isLogin,
    auth: state.auth.auth,
    nftDetails: state.NFTDetails?.nftDetails,
});

export default connect(mapStateToProps)(Edit);
